import { Divider, Select, Typography } from "antd";
import { filterTypeOptionsByResource } from "components/Routing/constants";
import { EditableList } from "components/common/EditableList";
import { StateRadioGroup } from "components/common/StateRadioGroup";
import {
  StyledFormItem,
  standardFormItemCols,
} from "components/common/forms/styles";
import { noop } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  integrationLabels,
  resourceTypes,
} from "shared/types/workflow/constants";
import {
  Filter,
  IntegrationResourceRule,
  ResourceRule,
} from "shared/types/workflow/types";
import { widetype } from "shared/util/collections";
import { keyLabelObjectToOptions } from "utils/antd-utilities";

import { RoutingRuleEditorContext } from "../../store/RoutingRuleEditorContext";
import { getDefaultResourcePreviewRule } from "../../utils";
import { EditorBackButton } from "../EditorBackButton";
import { ResourceFilterPreview } from "../previews/ResourceFilterPreview";
import { ResourcePreview } from "../previews/ResourcePreview";
import { ResourceFilterEditor } from "./ResourceFilterEditor";

const resourceIntegrationOptions: {
  label: string;
  value: IntegrationResourceRule["service"];
}[] = Object.entries(integrationLabels).map(([value, label]) => ({
  label,
  value: value as IntegrationResourceRule["service"],
}));

export const ResourceEditor: React.FC = () => {
  const {
    resource,
    setResourceType,
    setResourceService,
    removeResourceFilter,
  } = useContext(RoutingRuleEditorContext);

  const handleSelectResourceType = useCallback(
    (type: ResourceRule["type"]) => {
      setResourceType(type);
    },
    [setResourceType]
  );

  const [isAddingNewFilter, setIsAddingNewFilter] = useState(false);
  const [editingFilterIndex, setEditingFilterIndex] = useState<number | null>(
    null
  );
  const [localFilterType, setLocalFilterType] = useState<string>();
  const [localFilterEffect, setLocalFilterEffect] =
    useState<Filter["effect"]>();
  const [localFilterPattern, setLocalFilterPattern] = useState<string>();
  const [localFilterKey, setLocalFilterKey] = useState<string>();
  const [localFilterValue, setLocalFilterValue] = useState<boolean>();

  const filterEntries = useMemo(() => {
    if (resource?.type !== "integration") {
      return [];
    }
    // Typescript doesn't realize that resource.filters can be a BooleanFilter or a PatternFilter
    // cast to Filter which is the union of the two
    return Object.entries(resource.filters || []) as [string, Filter][];
  }, [resource]);

  const availableFilterTypes = useMemo(() => {
    if (resource?.type !== "integration") {
      return [];
    }
    const used = resource.filters ? Object.keys(resource.filters) : [];
    return keyLabelObjectToOptions(
      filterTypeOptionsByResource[resource.service]
    ).filter(({ value }) => !used.includes(value));
  }, [resource]);

  const resourceFilterDirty = useMemo(() => {
    if (resource?.type !== "integration") {
      return false;
    }
    if (isAddingNewFilter) {
      return true;
    }
    if (editingFilterIndex !== null && resource.filters) {
      const [fType, f] = filterEntries[editingFilterIndex];
      if (fType !== localFilterType || f?.effect !== localFilterEffect)
        return true;

      return (
        fType !== localFilterType ||
        f?.effect !== localFilterEffect ||
        (f.effect !== "removeAll" &&
          "key" in f &&
          (f.pattern !== localFilterPattern || f.key !== localFilterKey)) ||
        ("value" in f && f.value !== localFilterValue)
      );
    }
    return false;
  }, [
    resource,
    isAddingNewFilter,
    editingFilterIndex,
    localFilterType,
    localFilterEffect,
    localFilterPattern,
    localFilterKey,
    localFilterValue,
    filterEntries,
  ]);

  const handleStartAddingNewFilter = useCallback(() => {
    setLocalFilterType(undefined);
    setLocalFilterEffect(undefined);
    setLocalFilterPattern(undefined);
    setLocalFilterKey(undefined);
    setIsAddingNewFilter(true);
  }, []);

  const handleStartEditingFilter = useCallback(
    (index: number) => {
      const [fType, f] = filterEntries[index];
      setLocalFilterType(fType);
      setLocalFilterEffect(f.effect);
      if (f.effect !== "removeAll") {
        if ("value" in f) {
          setLocalFilterValue(f.value);
        } else {
          setLocalFilterPattern(f.pattern);
          setLocalFilterKey(f.key);
        }
      } else {
        setLocalFilterPattern(undefined);
        setLocalFilterKey(undefined);
        setLocalFilterValue(undefined);
      }
      setEditingFilterIndex(index);
    },
    [filterEntries]
  );

  const handleBackToList = useCallback(() => {
    setIsAddingNewFilter(false);
    setEditingFilterIndex(null);
  }, []);

  const handleRemoveFilter = useCallback(
    (index: number) => {
      const [fType, f] = filterEntries[index];
      if (fType && f) {
        removeResourceFilter(fType);
      }
    },
    [removeResourceFilter, filterEntries]
  );

  return (
    <>
      <Typography.Title level={4}>Resource</Typography.Title>
      <StateRadioGroup
        value={resource?.type}
        onChange={handleSelectResourceType}
        items={widetype.keys(resourceTypes).map((res) => ({
          value: res,
          display: (
            <ResourcePreview resource={getDefaultResourcePreviewRule(res)} />
          ),
        }))}
      />
      {resource?.type === "integration" && (
        <>
          <Divider />
          <StyledFormItem
            label="Integration"
            rules={[{ required: true }]}
            {...standardFormItemCols}
          >
            <Select
              value={resource.service}
              options={resourceIntegrationOptions}
              onSelect={setResourceService}
            />
          </StyledFormItem>
          <Typography.Title level={5}>Filters</Typography.Title>
          {isAddingNewFilter || editingFilterIndex !== null ? (
            <LocalResourceFilterContext.Provider
              value={{
                localFilterType,
                setLocalFilterType,
                localFilterEffect,
                setLocalFilterEffect,
                localFilterKey,
                setLocalFilterKey,
                localFilterPattern,
                setLocalFilterPattern,
                localFilterValue,
                setLocalFilterValue,
                isDirty: resourceFilterDirty,
                isNew: isAddingNewFilter,
                onSave: handleBackToList,
              }}
            >
              <EditorBackButton
                onBack={handleBackToList}
                isDirty={resourceFilterDirty}
                isAdding={isAddingNewFilter}
                name="Resource Filter"
              />
              <ResourceFilterEditor
                resource={resource}
                filterType={
                  editingFilterIndex !== null
                    ? filterEntries[editingFilterIndex][0]
                    : undefined
                }
              />
            </LocalResourceFilterContext.Provider>
          ) : (
            <EditableList
              items={filterEntries.map((entry) => ({
                type: entry[0],
                value: entry[1],
              }))}
              onEditItem={handleStartEditingFilter}
              onAddItem={handleStartAddingNewFilter}
              onDeleteItem={handleRemoveFilter}
              renderItem={(filter) => (
                <ResourceFilterPreview
                  filter={filter.value}
                  filterType={filter.type}
                />
              )}
              addButtonText="Add New Resource Filter"
              addButtonDisabled={availableFilterTypes.length === 0}
            />
          )}
        </>
      )}
    </>
  );
};

type LocalResourceFilter = {
  localFilterType: string | undefined;
  setLocalFilterType: (type: string) => void;
  localFilterEffect: Filter["effect"] | undefined;
  setLocalFilterEffect: (effect: Filter["effect"]) => void;
  localFilterKey: string | undefined;
  setLocalFilterKey: (key: string) => void;
  localFilterPattern: string | undefined;
  setLocalFilterPattern: (pattern: string) => void;
  localFilterValue: boolean | undefined;
  setLocalFilterValue: (value: boolean | undefined) => void;
  isDirty: boolean;
  isNew: boolean;
  onSave: () => void;
};

export const LocalResourceFilterContext = createContext<LocalResourceFilter>({
  localFilterType: undefined,
  setLocalFilterType: noop,
  localFilterEffect: undefined,
  setLocalFilterEffect: noop,
  localFilterKey: undefined,
  setLocalFilterKey: noop,
  localFilterPattern: undefined,
  setLocalFilterPattern: noop,
  localFilterValue: undefined,
  setLocalFilterValue: noop,
  isDirty: false,
  isNew: false,
  onSave: noop,
});
