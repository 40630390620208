import { DeleteOutlined, EditTwoTone } from "@ant-design/icons";
import { Button, Space, Table, Tag, Typography, notification } from "antd";
import type { FormInstance } from "antd/es/form";
import capitalize from "lodash/capitalize";
import { useFirestoreDoc } from "providers/FirestoreProvider";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { installedIds } from "shared/install/installed";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { rootConfig } from "shared/integrations/resources/gcloud/util";
import {
  PgComponentConfig,
  PgIntegration,
} from "shared/integrations/resources/postgres/types";

import { Tenant } from "../../Login";
import { useAuthFetch } from "../../Login/hook";
import { IntegrationCard, IntegrationCardOverrides } from "../IntegrationCard";
import Workflows from "./Workflows";
import { StyledConfigFooter } from "./styles";
import { getCloudSqlInstanceName } from "./utils";

export const PostgresIconUrl =
  "https://wiki.postgresql.org/images/a/a4/PostgreSQL_logo.3colors.svg";

const { Paragraph } = Typography;
const { Column } = Table;

export type NotificationType = "error" | "success";
const PG_COMPONENT = ["workflows"];

export const Postgres: React.FC<{
  cardOverride?: IntegrationCardOverrides;
  onStateTransition?: (newState: string | undefined) => void;
}> = ({ cardOverride }) => {
  const tenantId = useContext(Tenant);
  const [error, setError] = useState<string>();
  const { component } = useParams<{ component: string }>();
  const [isFetch, setIsFetching] = useState(false);
  const [config, setConfig] = useState<Partial<PgComponentConfig>>();
  const navigate = useNavigate();
  const [isNewConfig, setIsNewConfig] = useState(false);

  const formRef = React.useRef<FormInstance>(null);
  const [api, contextHolder] = notification.useNotification();
  const isValidComponent = PG_COMPONENT.includes(component as string);

  const authFetch = useAuthFetch(setError, setIsFetching);

  const integrationPath = useMemo(
    () => `o/${tenantId}/integrations/pg`,
    [tenantId]
  );
  const gcloudPath = useMemo(
    () => `o/${tenantId}/integrations/gcloud`,
    [tenantId]
  );

  const integrationDoc = useFirestoreDoc<PgIntegration>(integrationPath, {
    live: true,
  });
  const gcloudDoc = useFirestoreDoc<GcloudIntegration>(gcloudPath);
  const projectIds = useMemo(
    () => (gcloudDoc.doc ? installedIds("iam-write", gcloudDoc.doc.data) : []),
    [gcloudDoc]
  );
  const serviceAccountEmail = useMemo(
    () =>
      gcloudDoc.doc ? rootConfig(gcloudDoc.doc.data).serviceAccountEmail : "",
    [gcloudDoc.doc]
  );

  const openNotificationWithIcon = useCallback(
    (
      type: NotificationType,
      {
        message,
        description,
      }: {
        message?: string;
        description?: string;
      }
    ) => {
      api[type]({
        message,
        description,
        duration: 2,
      });
    },
    [api]
  );

  const items = useMemo(
    () => integrationDoc.doc?.data.workflows?.items ?? [],
    [integrationDoc.doc?.data.workflows?.items]
  );

  const onRemove = useCallback(
    async (
      instanceName: string | undefined,
      databaseName: string | undefined
    ) => {
      const response = await authFetch(
        `integrations/pg/config/${instanceName}/${databaseName}`,
        {
          method: "DELETE",
        }
      );
      if (response) {
        openNotificationWithIcon("success", {
          message: "Integration Removed",
          description: "PostgreSQL integration has been removed successfully.",
        });
      }
    },

    [authFetch, openNotificationWithIcon]
  );

  useEffect(() => {
    if (error) {
      openNotificationWithIcon("error", {
        message: "Error",
        description: "PostgreSQL integration encountered an error.",
      });
    }
  }, [error, openNotificationWithIcon]);

  const handleNewConfig = () => {
    setError(undefined);
    setConfig(undefined);
    setIsNewConfig(true);
    navigate("./workflows");
    formRef.current?.resetFields();
  };

  const handleEditConfig = (record: Partial<PgComponentConfig>) => {
    setError(undefined);
    setConfig(record);
    setIsNewConfig(false);
    navigate(`./workflows`);
  };

  const ZeroState = () => {
    return (
      <div>
        <Paragraph>
          Welcome to PostgreSQL integration setup. To begin, please select the
          PostgreSQL integration type and provide the required details for
          installation.
        </Paragraph>
        <Button type="primary" onClick={handleNewConfig}>
          Add New PostgreSQL Configuration
        </Button>
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <IntegrationCard
        integration="pg"
        component={isValidComponent ? component : undefined}
        integrationDoc={integrationDoc.doc}
        title="PostgreSQL"
        canRemove={false}
        logo={PostgresIconUrl}
        {...cardOverride}
      >
        {component === "workflows" ? (
          <Workflows
            config={config}
            serviceAccountEmail={serviceAccountEmail}
            openNotificationWithIcon={openNotificationWithIcon}
            projectIds={projectIds}
            isNewConfig={isNewConfig}
            setIsNewConfig={setIsNewConfig}
          />
        ) : (
          <>
            {!items.length && !integrationDoc.loading ? (
              <ZeroState />
            ) : (
              <Table
                dataSource={items}
                pagination={false}
                loading={integrationDoc.loading || isFetch}
                rowKey={(record) =>
                  `${record.instanceName}-${record.databaseName}`
                }
                footer={() => (
                  <StyledConfigFooter>
                    <Button type="primary" onClick={handleNewConfig}>
                      Add New Config
                    </Button>
                  </StyledConfigFooter>
                )}
              >
                <Column
                  title="Integration Type"
                  dataIndex="integrationType"
                  key="integrationType"
                />
                <Column
                  title="Project ID"
                  dataIndex="projectId"
                  key="projectId"
                />
                <Column
                  title="Instance Name"
                  dataIndex="instanceName"
                  key="instanceName"
                  render={(instanceName: string) => (
                    <span>{getCloudSqlInstanceName(instanceName)}</span>
                  )}
                />
                <Column
                  title="Database"
                  dataIndex="databaseName"
                  key="databaseName"
                />
                <Column
                  title="Installed?"
                  dataIndex="state"
                  key="state"
                  render={(state) => (
                    <Tag color="blue" key={state}>
                      {capitalize(state)}
                    </Tag>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={(_: any, record: Partial<PgComponentConfig>) => {
                    return (
                      <Space size="middle">
                        <Button
                          icon={<EditTwoTone />}
                          onClick={() => {
                            handleEditConfig(record);
                          }}
                        />
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() =>
                            onRemove(record.instanceName, record.databaseName)
                          }
                        />
                      </Space>
                    );
                  }}
                />
              </Table>
            )}
          </>
        )}
      </IntegrationCard>
    </>
  );
};
