import yaml from "js-yaml";
import { create } from "zustand";

type RoutingRulesState = {
  currentVersion: string | undefined;
  errors: string[];
  isLoading: boolean;
  isSubmitting: boolean;
  jsonRules: any[];
  selectedRule: any;
  selectedYamlRule: string;
  setCurrentVersion: (value: string | undefined) => void;
  setErrors: (value: string[]) => void;
  setIsLoading: (value: boolean) => void;
  setIsSubmitting: (value: boolean) => void;
  setJsonRules: (value: any[]) => void;
  setSelectedRule: (value: any) => void;
  setShowConfigRulesModal: (value: boolean) => void;
  setYamlRules: (value: string) => void;
  showConfigRulesModal: boolean;
  yamlRules: string;
};

export const useRoutingRulesStore = create<RoutingRulesState>((set) => ({
  currentVersion: undefined,
  isLoading: false,
  isSubmitting: false,
  jsonRules: [],
  yamlRules: "",
  errors: [],
  selectedRule: {},
  selectedYamlRule: "",
  showConfigRulesModal: false,
  setJsonRules: (value) => set({ jsonRules: value }),
  setIsLoading: (value) => set({ isLoading: value }),
  setErrors: (value) => set({ errors: value }),
  setYamlRules: (value) => set({ yamlRules: value }),
  setCurrentVersion: (currentVersion) => set({ currentVersion }),
  setSelectedRule: (value) =>
    set({
      selectedRule: value,
      selectedYamlRule: yaml.dump(value, { flowLevel: 4 }),
    }),
  setShowConfigRulesModal: (value) => {
    if (!value) {
      set({ selectedYamlRule: "" });
    }
    set({ showConfigRulesModal: value });
  },
  setIsSubmitting: (value) => set({ isSubmitting: value }),
}));
