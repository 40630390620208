import { SINGLETON_ITEM } from "shared/install/constants";
import { installedItem } from "shared/install/installed";
import { otherInstalled } from "shared/install/options";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";

import { gcloudAccessLogsInstructor } from "./access-logs";
import { iamInstructor } from "./iam";
import { orgWidePolicyInstructor } from "./org-wide-policy";
import { sharingRestrictionWarningLabeler } from "./root";
import { securityPerimeterInstructions } from "./security-perimeter";
import { sharingRestrictionInstructions } from "./sharing-restriction";
import { GcloudInstaller } from "./types";

export const gcloudInstaller: GcloudInstaller = {
  root: {
    items: {
      organizationId: {
        labeler: sharingRestrictionWarningLabeler,
      },
    },
  },
  "access-logs": {
    instructions: gcloudAccessLogsInstructor("project"),
    optionProvider: async (context) =>
      otherInstalled<GcloudIntegration>("iam-assessment")(context),
    prerequisiteMessages: async (context) => {
      if (installedItem("org-access-logs", context.config, SINGLETON_ITEM))
        return (
          <div>
            Cannot install project access logs with active organization access
            log installation
          </div>
        );
    },
    items: {},
  },
  "iam-assessment": {
    instructions: iamInstructor("project", "iam-assessment"),
  },
  "iam-write": {
    instructions: iamInstructor("project", "iam-write"),
  },
  "org-access-logs": {
    instructions: gcloudAccessLogsInstructor("organization"),
    items: {},
  },
  "org-iam-assessment": {
    instructions: iamInstructor("organization", "iam-assessment"),
  },
  "org-wide-policy": {
    instructions: orgWidePolicyInstructor,
  },
  "sharing-restriction": {
    instructions: sharingRestrictionInstructions,
    optionProvider: async (context) =>
      otherInstalled<GcloudIntegration>("iam-write")(context),
  },
  "security-perimeter": {
    instructions: securityPerimeterInstructions,
    items: {},
  },
};
