import { ApiFilled } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { useHasRole } from "components/Login/hook";
import * as Firestore from "firebase/firestore";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { Navigate } from "react-router";
import { URLSearchParams } from "url";

import { DB } from "../../providers/FirestoreProvider";
import { Cancellation } from "../../utils/cancel";
import { OwnerAlert } from "./OwnerAlert";
import {
  AuthContext,
  STATE_AUTH_CODE,
  STATE_INSTALLED,
  STATE_NO_AUTH,
  STATE_TOKEN_FETCH,
  sessionKeyForNonce,
  useIntegrationContext,
  useOAuth2Callback,
  useOAuth2Context,
} from "./useOAuth2Context";

// cf. https://auth0.com/docs/get-started/authentication-and-authorization-flow/authorization-code-flow-with-proof-key-for-code-exchange-pkce

export type OAuth2Props = {
  mode: "client_credentials" | "consent" | "pkce" | "secret";
  integration: string;
  authExtra?: string;
};

export interface RootIntegrationDoc {
  /** This integration's authorization URL (first leg of remote OAuth grant) */
  authUrl: string;
  /** The integration's client ID */
  clientId: string;
}

/** Support for OAuth2 grant flow.
 *
 * How this works:
 *
 * 1. Each integration has a corresponding Firebase document (stored in the tenant's /integrations/<integration> path),
 *    with the following potential data:
 *   a. In the root document, `state`, which, if present and equal to `installed`, indicates that this
 *      integration is successfully installed for the tenant
 *   b. In `/secrets/oauth-token`, `value`, which stores the integration's oauth token; this token
 *      can be written by the frontend, but not read
 * 2. When this component is first loaded, the Firestore `state` field will be missing, and an `Authorize`
 *    button will be rendered
 * 3. On clicking this button, the user is redirected to the integrations OAuth2 SSO grant page
 * 4. After completing grant on the remote, the user is redirected to `/integration/auth/_redirect`, with
 *    the OAuth2 state stored in URL parameters; note that this redirect must be tenant-independent,
 *    as this redirect must be hard-coded in the integration backend
 * 5. Upon landing on `/integration/auth/redirect`, the P0 app immediately redirects the user
 *    back to the page that renders this component, with an additional `auth_state=auth_code` URL
 *    parameter
 * 6. This component then renders a spinner, writes a token request to Firestore, and redirects back to this
 *    page with no search parameters
 * 7. The tenant service asynchronously executes either an OAuth2 PKCE code exchange or
 *    OAuth2 client secret exchange
 * 8. Upon completion of the code exchange, Firestore writes the `/secrets/oauth-token` document for this integration,
 *    then the root Firestore document for this integration is updated with `state` equal to `installed`
 * 9. This component then renders its children
 */
export const OAuth2: React.FC<PropsWithChildren<OAuth2Props>> = (props) => {
  const { authContext, authState, params, nonce, loading, tenantId } =
    useIntegrationContext(props.integration);

  if (loading) return <Spin />;

  switch (authState) {
    case STATE_INSTALLED:
      return <>{props.children}</>;
    case STATE_AUTH_CODE:
      // Note that multiple Integration components load at once, so when handling redirects, we have
      // to filter to the one Integration component whose redirect we are processing
      if (
        authContext !== undefined &&
        nonce !== null &&
        authContext.integration === props.integration
      ) {
        return (
          <OAuth2FetchToken
            mode={props.mode}
            integration={props.integration}
            {...{ authContext, params, nonce, tenantId }}
          />
        );
      }
      return <OAuth2Authorize {...props} />;
    case STATE_NO_AUTH:
      return <OAuth2Authorize {...props} />;
    case STATE_TOKEN_FETCH:
      return <Spin />;
  }
};

/** Renders a button to start OAuth2 PKCE authorization
 *
 * Implements item (2) from the documentation for `OAuth2Pkce`.
 *
 * By default the integration client ID is taken from /integrations.
 * However, a developer can override the integration client ID for a
 * tenant by adding the client ID at o/{tenant}/integrations/{integration}-install
 */
const OAuth2Authorize: React.FC<OAuth2Props> = (props) => {
  const { onAuthSubmit, authEnabled, authLoading } = useOAuth2Callback(props);
  const canInstall = useHasRole("owner");

  return authLoading ? (
    <Spin />
  ) : canInstall ? (
    <Button
      icon={<ApiFilled />}
      onClick={onAuthSubmit}
      type="primary"
      disabled={!authEnabled}
    >
      Install integration
    </Button>
  ) : (
    <OwnerAlert />
  );
};

/** Renders a spinner while asynchronously execution token exchange
 *
 * Implements item (6) from the documentation for `OAuth2Pkce`.
 */
const OAuth2FetchToken: React.FC<
  Pick<OAuth2Props, "integration" | "mode"> & {
    authContext: AuthContext;
    params: URLSearchParams;
    nonce: string;
    tenantId: string;
  }
> = ({ mode, authContext, params, nonce, integration, tenantId }) => {
  // Because React double-mounts in development, we can't use proper
  // useEffect semantics here. This is because the token exchange can only
  // ever work once, so cancelling the useEffect hook will cause future
  // token exchanges to fail. To work around this, we unfortunately have
  // to resort to a window.location.href hard reset of the React state :(

  const { clientId, verifier, oAuthRedirect } = authContext;

  const code = useMemo(() => params.get("code"), [params]);
  const adminConsent = useMemo(() => params.get("admin_consent"), [params]);
  const tenant = useMemo(() => params.get("tenant"), [params]);

  useEffect(() => {
    const cancellation = new Cancellation();
    const data: Record<string, string> = {
      integration,
      client_id: clientId,
      redirect_uri: oAuthRedirect,
    };
    if (mode === "consent") {
      if (adminConsent !== "True") return;
      if (!tenant) return;
      data.tenant = tenant;
    }
    if (mode === "pkce" || mode === "secret") {
      if (!code) return;
      data.code = code;
      data.grant_type = "authorization_code";
    }
    if (mode === "pkce") data.code_verifier = verifier;
    if (mode === "client_credentials") data.grant_type = "client_credentials";
    (async () => {
      try {
        await Firestore.setDoc(
          Firestore.doc(
            Firestore.collection(DB, `o/${tenantId}/token-requests`)
          ),
          data
        );
        if (cancellation.isCancelled) return;
        sessionStorage.removeItem(sessionKeyForNonce(nonce));
        window.location.href = window.location.pathname;
      } catch (err) {
        console.error(err);
      }
    })();
    return cancellation.cancel;
  }, [
    adminConsent,
    clientId,
    code,
    integration,
    mode,
    nonce,
    oAuthRedirect,
    tenant,
    tenantId,
    verifier,
  ]);

  return <Spin />;
};

/** Immediate OAuth2 redirect
 *
 * This component only exists to support OAuth2 redirects to a static (tenant-independent)
 * location; it immediately redirects to the page that hosts the `OAuth2Pkce` component
 * for this user's selected tenant and integration.
 *
 * It should be served by this app's root `/integration/auth/_redirect` route.
 *
 * Implements item (3) from the documentation for `OAuth2Pkce`.
 */
export const OAuth2Redirect: React.FC<object> = () => {
  const { authContext, params } = useOAuth2Context();
  let href = authContext === undefined ? "/" : authContext.redirectLocation;

  if (!params.has("error")) {
    href = href + `?${params.toString()}&auth_state=${STATE_AUTH_CODE}`;
  }
  return <Navigate to={href} />;
};
