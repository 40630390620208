// import { getEnvironment } from "utils/environment";
import { FrontendInstallContext } from "install/types";
import { SnowflakeIntegration } from "shared/integrations/resources/snowflake/types";
import { getEnvironment } from "utils/environment";

const BOUNDARY_VERSION = "_v1_0";

// Tested in https://app.snowflake.com/zooaerc/xv05056/w25F8PqcQFOf
const sanitizer = (
  param: string,
  returnType: "text" | "variant",
  indent: number
) => {
  const tab = Array(indent).fill(" ").join("");
  const errorString = `"Invalid SQL '" + ${param} + "' (only alphabetical characters and spaces allowed)"`;
  return `// Prevent SQL injection
  ${tab}if (!${param}.match(/^[A-Za-z ]+$/)) {
  ${tab}  return ${
    returnType === "text" ? errorString : `{ error: ${errorString}}`
  }
  // todo add 
  ${tab}}`;
};

export const iamWriteCommands = (
  context: FrontendInstallContext<SnowflakeIntegration>,
  id: string
) => {
  let suffix = "";
  const { developer } = getEnvironment();
  // Note that env var _must_ start with REACT_APP_ in order to be passed to the dev server
  // See https://create-react-app.dev/docs/adding-custom-environment-variables/
  if (developer) {
    suffix = "_" + developer.replace("-", "_");
  }
  const rsaPublicKey = context.config?.base?.[id]?.rsaPublicKey ?? "";
  return createSql(rsaPublicKey, suffix);
};

const createSql = (rsaPublicKey: string, suffix: string) => {
  const keyLines = rsaPublicKey
    .split("\n")
    .filter((l) => !l.startsWith("-----") || !l.trim());
  const strippedKey = keyLines.join("");
  return `USE ROLE ACCOUNTADMIN;                     -- Or any other role that can create databases and manage grants
  
  CREATE OR REPLACE DATABASE p0${suffix};             -- All of P0's operations operate in this database
  CREATE OR REPLACE SCHEMA p0${suffix}.boundary${BOUNDARY_VERSION}; -- and schema
  
  USE SCHEMA p0${suffix}.boundary${BOUNDARY_VERSION};
  
  -- Used by P0 to list schemas in a database
  CREATE OR REPLACE PROCEDURE get_schema_names(DBNAME text) RETURNS variant LANGUAGE javascript AS
  $$
    try {
      var output = [];
      var rs = snowflake.execute({
        sqlText: "SHOW SCHEMAS IN DATABASE IDENTIFIER(?)",
        binds: [DBNAME]
      });
      while (rs.next()) {
        output.push(rs.getColumnValue("name"));
      }
      return { result: output };
    } catch (err) {
      return { error: err.message };
    }
  $$
  ;
  
  -- Used by P0 to list tables in a schema
  CREATE OR REPLACE PROCEDURE get_tables(SCHEMANAME text) RETURNS variant LANGUAGE javascript AS
  $$
    try { 
      var output = [];
      var rs = snowflake.execute({
        sqlText: "SHOW TABLES IN SCHEMA IDENTIFIER(?)",
        binds: [SCHEMANAME]
      });
      while (rs.next()) {
        output.push({
          name: rs.getColumnValue("name"),
          database_name: rs.getColumnValue("database_name"),
          schema_name: rs.getColumnValue("schema_name"),
          kind: rs.getColumnValue("kind"),
          comment: rs.getColumnValue("comment"),
          cluster_by: rs.getColumnValue("comment"),
          rows: rs.getColumnValue("rows"),
          bytes: rs.getColumnValue("bytes"),
          owner: rs.getColumnValue("owner"),
          retention_time: rs.getColumnValue("retention_time"),
          automatic_clustering: rs.getColumnValue("automatic_clustering"),
          change_tracking: rs.getColumnValue("change_tracking"),
          is_external: rs.getColumnValue("is_external")
        });
      }
      return { result: output };
    } catch (err) {
      return { error: err.message };
    }
  $$
  ;
  
  -- Used by P0 to list views in a schema
  CREATE OR REPLACE PROCEDURE get_views(SCHEMANAME text) RETURNS variant LANGUAGE javascript AS
  $$
    try {
      var output = [];
      var rs = snowflake.execute({
        sqlText: "SHOW VIEWS IN SCHEMA IDENTIFIER(?)",
        binds: [SCHEMANAME]
      });
      while (rs.next()) {
        output.push({
          name: rs.getColumnValue("name"),
          database_name: rs.getColumnValue("database_name"),
          schema_name: rs.getColumnValue("schema_name"),
          reserved: rs.getColumnValue("reserved"),
          owner: rs.getColumnValue("owner"),
          comment: rs.getColumnValue("comment"),
          text: rs.getColumnValue("text"),
          is_secure: rs.getColumnValue("is_secure"),
          is_materialized: rs.getColumnValue("is_materialized"),
          change_tracking: rs.getColumnValue("change_tracking")
        });
      }
      return { result: output };
    } catch (err) {
      return { error: err.message };
    }
  $$
  ;
  
  
  -- When identifying users by email, P0 will call this function to convert an email to a Snowflake user
  CREATE OR REPLACE PROCEDURE get_users(FILTER text) RETURNS variant LANGUAGE javascript AS
  $$${
    // Keep FILTER_ALLOW_LIST up-to-date with UserFilter in integrations/snowflake/permissioner.ts
    ""
  }  // Use allow list to prevent SQL injection
    FILTER_ALLOW_LIST = ["LOGIN_NAME", "EMAIL"];
    if (!FILTER_ALLOW_LIST.includes(FILTER.toUpperCase())) {
      return { error: "invalid column SQL " + FILTER };
    }
    var output = [];
    var rs = snowflake.execute({
      sqlText: "SELECT " + FILTER + ", name FROM snowflake.account_usage.users WHERE deleted_on IS NULL AND " + FILTER + " IS NOT NULL"
    });
    while (rs.next()) {
      output.push([rs.getColumnValue(FILTER), rs.getColumnValue("NAME")]);
    }
    return { result: output };
  $$
  ;
  
  -- Used by P0 to determine a relation's owner (for DDL grants)
  CREATE OR REPLACE PROCEDURE get_owner(DATABASE text, SCHEMA text, OBJECT_TYPE text, OBJECT text) RETURNS variant LANGUAGE javascript AS
  $$
    try {
      ${sanitizer("OBJECT_TYPE", "variant", 4)}
      var name_col = OBJECT_TYPE + "_NAME";
      var schema_col = OBJECT_TYPE + "_SCHEMA";
      var type_plural = OBJECT_TYPE === "schema" ? "schemata" : OBJECT_TYPE + "s";
      var info_relation = DATABASE + ".information_schema." + type_plural;
      var output = [];
      var rs = snowflake.execute({
        sqlText: "SELECT * FROM IDENTIFIER(?) WHERE IDENTIFIER(?) = ? AND IDENTIFIER(?) = ?",
        binds: [info_relation, name_col, OBJECT, schema_col, SCHEMA]
      });
      while (rs.next()) {
        output.push({
          name: rs.getColumnValue(name_col),
          owner: rs.getColumnValue(OBJECT_TYPE + "_OWNER"),
        });
      }
      return { result: output };
    } catch (err) {
      return { error: err.message };
    }
  $$
  ;
  
  -- Used by P0 to create custom roles
  CREATE OR REPLACE PROCEDURE create_role(ROLE_NAME text) RETURNS text LANGUAGE javascript AS
  $$
    try {
      if (!ROLE_NAME.toLowerCase().startsWith("p0_")) {
        return "role name must start with \`p0_\`";
      }
      snowflake.execute({
        sqlText: "CREATE ROLE IDENTIFIER(?) COMMENT = 'Managed by P0'",
        binds: [ROLE_NAME]
      });
      return null;
    } catch (err) {
      return err.message;
    }
  $$
  ;
  
  -- Used by P0 to drop custom roles
  CREATE OR REPLACE PROCEDURE drop_role(ROLE_NAME text) RETURNS text LANGUAGE javascript AS
  $$
    try {
      if (!ROLE_NAME.toLowerCase().startsWith("p0_")) {
        return "role name must start with \`p0_\`";
      }
      snowflake.execute({ sqlText: "DROP ROLE IF EXISTS IDENTIFIER(?)", binds: [ROLE_NAME] });
      return null;
    } catch (err) {
      return err.message;
    }
  $$
  ;
  
  -- Used by P0 to grant roles to (non-P0) roles
  CREATE OR REPLACE PROCEDURE grant_role_to_role(ROLE_NAME text, REQUESTING_ROLE text) RETURNS text LANGUAGE javascript AS
  $$
    try {
      if (REQUESTING_ROLE.toLowerCase() === "p0_service_account${suffix}") {
        return "can not escalate P0 role privileges";
      }
      if (ROLE_NAME.toLowerCase() === "p0_permissioner${suffix}") {
        return "can not assume the P0 permissioner role";
      }
      snowflake.execute({
        sqlText: "GRANT ROLE IDENTIFIER(?) TO ROLE IDENTIFIER(?)",
        binds: [ROLE_NAME, REQUESTING_ROLE]
      });
      return null;
    } catch (err) {
      return err.message;
    }
  $$
  ;
  -- Used by P0 to revoke roles from role
  CREATE OR REPLACE PROCEDURE revoke_role_from_role(ROLE_NAME text, REQUESTING_ROLE text) RETURNS text LANGUAGE javascript AS
  $$
    try {
      snowflake.execute({
        sqlText: "REVOKE ROLE IDENTIFIER(?) FROM ROLE IDENTIFIER(?)",
        binds: [ROLE_NAME, REQUESTING_ROLE]
      });
      return null;
    } catch (err) {
      return err.message;
    }
  $$
  ;
  
  -- Used by P0 to grant roles to (non-P0) users
  CREATE OR REPLACE PROCEDURE grant_role(ROLE_NAME text, USER text) RETURNS text LANGUAGE javascript AS
  $$
    try {
      if (USER.toLowerCase() === "p0_service_account${suffix}") {
        return "can not escalate P0 service account privileges";
      }
      if (ROLE_NAME.toLowerCase() === "p0_permissioner${suffix}") {
        return "can not assume the P0 permissioner role";
      }
      snowflake.execute({
        sqlText: "GRANT ROLE IDENTIFIER(?) TO USER IDENTIFIER(?)",
        binds: [ROLE_NAME, USER]
      });
      return null;
    } catch (err) {
      return err.message;
    }
  $$
  ;
  
  -- Used by P0 to revoke roles from users
  CREATE OR REPLACE PROCEDURE revoke_role(ROLE_NAME text, USER text) RETURNS text LANGUAGE javascript AS
  $$
    try {
      snowflake.execute({
        sqlText: "REVOKE ROLE IDENTIFIER(?) FROM USER IDENTIFIER(?)",
        binds: [ROLE_NAME, USER]
      });
      return null;
    } catch (err) {
      return err.message;
    }
  $$
  ;
  
  -- Used by P0 to assign privileges to roles
  CREATE OR REPLACE PROCEDURE assign_privileges(ROLE_NAME text, PRIVILEGES array, OBJECT_TYPE text, OBJECTS array)
  RETURNS text
  LANGUAGE javascript AS
  $$
    try {
      if (ROLE_NAME.toLowerCase() === "p0_permissioner${suffix}") {
        return "can not escalate privileges of p0_permissioner${suffix}";
      }
      for (var p of PRIVILEGES) {
        ${sanitizer("p", "text", 6)}
      }
      ${sanitizer("OBJECT_TYPE", "text", 4)}
      var FILTERED_PRIVILEGES=[];
      for (var p of PRIVILEGES) {
        if (p !== "OWNERSHIP") {
          FILTERED_PRIVILEGES.push(p);
        }
      }
      var privilege_expr = FILTERED_PRIVILEGES.join(", ");
      
      for (var o of OBJECTS) {
        if(PRIVILEGES.includes("OWNERSHIP")) {
          snowflake.execute({
            sqlText: "GRANT OWNERSHIP ON " + OBJECT_TYPE + " IDENTIFIER(?) TO ROLE IDENTIFIER(?) COPY CURRENT GRANTS",
            binds: [o, ROLE_NAME]
          });
        }
        if(privilege_expr) {
          if(OBJECT_TYPE==="ACCOUNT")
            snowflake.execute({
              sqlText: "GRANT " + privilege_expr + " ON " + OBJECT_TYPE + " TO ROLE IDENTIFIER(?)",
              binds: [ROLE_NAME]
            });
          else
            snowflake.execute({
              sqlText: "GRANT " + privilege_expr + " ON " + OBJECT_TYPE + " IDENTIFIER(?) TO ROLE IDENTIFIER(?)",
              binds: [o, ROLE_NAME]
            });
        }
      }
    } catch (err) {
      return err.message;
    }
  $$
  ;
  
  -- Used by P0 to revoke privileges from roles
  CREATE OR REPLACE PROCEDURE revoke_privileges(ROLE_NAME text, PRIVILEGES array, OBJECT_TYPE text, OBJECTS array)
  RETURNS text
  LANGUAGE javascript AS
  $$
    try {
      if (ROLE_NAME.toLowerCase() === "p0_permissioner${suffix}") {
        return "can not escalate privileges of p0_permissioner${suffix}";
      }
      for (var p of PRIVILEGES) {
        ${sanitizer("p", "text", 6)}
      }
      ${sanitizer("OBJECT_TYPE", "text", 4)}
      var privilege_expr = PRIVILEGES.join(", ");
      for (var o of OBJECTS) {
        if(OBJECT_TYPE==="ACCOUNT")
          snowflake.execute({
            sqlText: "REVOKE " + privilege_expr + " ON " + OBJECT_TYPE + " FROM ROLE IDENTIFIER(?) CASCADE",
            binds: [ROLE_NAME]
          });
        else
          snowflake.execute({
            sqlText: "REVOKE " + privilege_expr + " ON " + OBJECT_TYPE + " IDENTIFIER(?) FROM ROLE IDENTIFIER(?) CASCADE",
            binds: [o, ROLE_NAME]
          });
      }
    } catch (err) {
      return err.message;
    }
  $$
  ;
  
  -- Used by P0 to query privileges a role has
  DROP PROCEDURE IF EXISTS get_role_grants (varchar, varchar);
  
  -- Used by P0 to query privileges of a list of roles in database
  CREATE OR REPLACE PROCEDURE get_role_grants(DB text, ROLES array) RETURNS variant LANGUAGE javascript AS
  $$
    try {
      var output = [];
      var tableIdentifier = DB + ".information_schema.object_privileges";
      var identifiers = [tableIdentifier];
      var bindings = [];
      for (var ROLE of ROLES) {
        identifiers.push(ROLE);
        bindings.push('?');
      }
      var bindingStmt = bindings.join(',');
      var stmt = snowflake.createStatement({
        sqlText: "select * from IDENTIFIER(?) WHERE GRANTEE in (" + bindingStmt + ")",
        binds: identifiers
      });
      var rs = stmt.execute();
      while (rs.next()) {
        output.push({
          "grantor": rs.getColumnValue('GRANTOR'),
          "grantee": rs.getColumnValue('GRANTEE'),
          "object_catalog": rs.getColumnValue('OBJECT_CATALOG'),
          "object_schema": rs.getColumnValue('OBJECT_SCHEMA'),
          "object_name": rs.getColumnValue('OBJECT_NAME'),
          "object_type": rs.getColumnValue('OBJECT_TYPE'),
          "privilege_type": rs.getColumnValue('PRIVILEGE_TYPE'),
          "is_grantable": rs.getColumnValue('IS_GRANTABLE'),
          "created": rs.getColumnValue('CREATED')
        });
      }
      return { "result": output };
    } catch (err) {
      return { "err": err.message };
    }
  $$
  ;
  
  CREATE OR REPLACE WAREHOUSE p0_wh${suffix} WITH WAREHOUSE_SIZE = XSMALL;  -- P0 uses this to register and execute grant requests
  CREATE OR REPLACE ROLE p0_permissioner${suffix};                          -- This is the role that P0 uses to manage access
  
  -- P0 is only allowed to call the stored procedures above
  GRANT USAGE ON WAREHOUSE p0_wh${suffix} TO p0_permissioner${suffix};
  GRANT USAGE ON DATABASE p0${suffix} TO ROLE p0_permissioner${suffix};
  GRANT USAGE ON SCHEMA p0${suffix}.boundary${BOUNDARY_VERSION} TO ROLE p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.get_users(text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.get_owner(text, text, text, text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.create_role(text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.drop_role(text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.grant_role(text, text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.revoke_role(text, text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.grant_role_to_role(text, text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.revoke_role_from_role(text, text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.assign_privileges(text, array, text, array) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.revoke_privileges(text, array, text, array) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.get_schema_names(text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.get_tables(text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.get_views(text) TO p0_permissioner${suffix};
  GRANT USAGE ON PROCEDURE p0${suffix}.boundary${BOUNDARY_VERSION}.get_role_grants(text, array) TO role p0_permissioner${suffix};
  -- This allows P0 to list your warehouses
  GRANT MONITOR USAGE ON ACCOUNT TO ROLE p0_permissioner${suffix};
  
  CREATE OR REPLACE USER p0_service_account${suffix}  -- P0 operates as this account
    DEFAULT_ROLE = p0_permissioner${suffix}
    DEFAULT_SECONDARY_ROLES = ()
    RSA_PUBLIC_KEY = '${strippedKey}'
  ;
  GRANT ROLE p0_permissioner${suffix} TO USER p0_service_account${suffix};`;
};
