import { Typography } from "antd";
import P0SecurityFeaturesLink from "components/common/P0SecurityFeaturesLink";
import { getFirestoreCollection } from "providers/FirestoreProvider";
import { Link } from "react-router-dom";
import { snowflakeAccountsPath } from "shared/integrations/resources/snowflake/constants";
import { snowflake } from "shared/integrations/resources/snowflake/types";
import { DirectoryGroup } from "shared/types/directory";

import { SnowflakeInstaller } from "../types";
import { iamWriteCommands } from "./commands";

const iamWriteInstructions: SnowflakeInstaller["iam-write"]["instructions"] = (
  context,
  id
) => {
  const coerceAccount = (id: string) => id.replace(".", "-").toUpperCase();
  const coercedId = coerceAccount(id);
  return {
    help: (
      <>
        Copy the text below and run it in account{" "}
        <Typography.Text code key={coercedId}>
          {coercedId}
        </Typography.Text>
      </>
    ),
    commands: {
      shell: [
        {
          command: iamWriteCommands(context, coercedId),
        },
      ],
    },
    labeler: () => (
      <>
        Please refer to the Snowflake documentation{" "}
        <Link
          to="https://docs.snowflake.com/en/user-guide/admin-account-identifier.html"
          target="_blank"
          rel="noreferrer"
        >
          &ldquo;Account Identifiers&rdquo;
        </Link>{" "}
        in order to determine your identifier.
        <P0SecurityFeaturesLink to={"https://p0.dev/blog/snowflake-least"} />
      </>
    ),
  };
};

export const snowflakeIamWriteInstaller = (
  groups?: DirectoryGroup[]
): SnowflakeInstaller["iam-write"] => ({
  instructions: iamWriteInstructions,
  items: {
    defaultWarehouse: {
      optionProvider: async (context, id) => {
        const integrationPath = snowflakeAccountsPath(
          context.tenantId,
          id ?? ""
        );
        const warehousesCollection =
          await getFirestoreCollection<snowflake.Warehouse>(
            `${integrationPath}/warehouses`
          );

        return warehousesCollection.map((w) => ({
          id: w.id,
          label: w.data?.name,
        }));
      },
      refresh: async (context, id) => {
        const { authFetch, config, tenantId } = context;
        await authFetch(`integrations/snowflake/refresh`, {
          method: "POST",
          json: { config, tenantId, accountId: id },
        });
      },
    },
    uidColumn: {
      optionProvider: async () => {
        const UID_OPTIONS = [
          { id: "EMAIL", label: `Manually, with email in email` },
          { id: "LOGIN_NAME", label: "Manually, with email in login name" },
        ];

        const GROUP_OPTIONS = (groups ?? [])?.map((group) => ({
          id: group.id,
          label: `By SCIM via membership in ${group.label}`,
        }));

        return [...UID_OPTIONS, ...GROUP_OPTIONS];
      },
    },
  },
});
