import { IamShowOptions } from "shared/assessment/constants";
import { TargetNodeType } from "shared/types/assessment/data";
import {
  QueryParamConfig,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export const useControls = () => {
  const [controls, setControls] = useQueryParams({
    display: withDefault(createEnumParam(["graph", "table"]), "table"),
    where: withDefault(StringParam, ""),
    show: withDefault(
      createEnumParam(Object.values(IamShowOptions).map((v) => v.value)),
      "grant"
    ) as QueryParamConfig<TargetNodeType>,
  });
  return { controls, setControls };
};
