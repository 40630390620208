import { Descriptions, Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { GraphTooltip } from "components/GraphTable/GraphTooltip";
import pluralize from "pluralize";
import { useContext } from "react";
import { CredentialNode } from "shared/types/assessment/data/credential";

import { ConsumerHelpToolTip, ConsumersList } from "../../cells/ConsumersList";
import { CredentialDisplay } from "../../cells/Credential";
import { LastAuthenticated } from "../../cells/LastAuthenticated";
import { LastRotated } from "../../cells/LastRotated";
import { RiskGroupedPermissionList } from "../../cells/PermissionAggregate";
import { PrincipalCell, PrincipalLink } from "../../cells/Principal";
import { NodeDescriptions } from "../NodeDescriptions";

const { Item } = Descriptions;

export const CredentialNodeDisplay: React.FC<{
  node: CredentialNode;
}> = ({ node }) => {
  const { integration } = useContext(ScopeContext);
  const [authn] = node.aggregates.authn;
  const [principal] = node.aggregates.principal;
  const grantCount = node.aggregates.grants.length;
  return (
    <NodeDescriptions>
      <Item label="Credential">
        <CredentialDisplay credential={node.data} id={node.key} />
      </Item>
      <Item label="Principal">
        <PrincipalLink data={principal} type="short" show="grant" />{" "}
        <PrincipalCell principalData={principal} />
      </Item>
      <Item label="Last Used">
        <LastAuthenticated principal={principal} last={authn.lastAuthnTime} />
      </Item>

      <Item label="Used By">
        {authn.consumers ? (
          <ConsumersList consumers={authn.consumers} />
        ) : authn.lastAuthnTime === 0 ? (
          "None"
        ) : (
          <GraphTooltip title={<ConsumerHelpToolTip />}>Unknown</GraphTooltip>
        )}
      </Item>
      <Item label="Last Rotated">
        <LastRotated credential={node.data} />
      </Item>
      <Item label="Grants">
        <Typography.Paragraph>
          This principal can exercise {grantCount}{" "}
          {pluralize("grant", grantCount)}
        </Typography.Paragraph>
        <PrincipalLink data={principal} show="principal" />
      </Item>
      {integration && (
        <Item label="Risks">
          <RiskGroupedPermissionList
            permissions={node.aggregates.permissions}
            integration={integration}
            showControl
          />
        </Item>
      )}
    </NodeDescriptions>
  );
};
