import { Typography } from "antd";
import {
  FrontendComponentInstaller,
  FrontendInstallContext,
} from "install/types";
import { capitalize, omit } from "lodash";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import {
  ConfigOf,
  Element,
  InstallSpec,
  ItemComponent,
  Option,
} from "shared/install/types";

import { ComponentProps, NEW_SENTINEL } from "./Component";
import { configPath } from "./Config";
import { ConfigForm } from "./ConfigForm";

export type NewProps<T extends InstallSpec> = ComponentProps<T> & {
  component: ItemComponent<any>;
  context: FrontendInstallContext<ConfigOf<T>>;
};

/** Recursively extracts "new"-step fields from a component schema */
const pickNew = <S extends Record<string, Element>>(
  schema: S
): Record<string, Element> => {
  const output: Record<string, Element> = {};
  for (const [key, item] of Object.entries(schema)) {
    if (item.step !== "new") continue;
    if (item.type !== "select") {
      output[key] = item;
    } else {
      const options: Record<string, Option<any>> = {};
      for (const [k, option] of Object.entries(item.options)) {
        options[k] = { ...option, schema: pickNew(option.schema) };
      }
      output[key] = { ...item, options };
    }
  }
  return output;
};

export const New = <T extends InstallSpec>(props: NewProps<T>) => {
  const {
    authFetch,
    component,
    componentKey,
    config,
    context,
    installer,
    itemKind,
  } = props;
  const navigate = useNavigate();
  const schema = {
    id: {
      ...omit(component, "schema"),
      label: `${capitalize(itemKind)} identifier`,
      description: undefined,
      step: "new",
    },
    ...pickNew(component.schema),
  };
  const submit = useCallback(
    async (item: object & { id: string }) => {
      if (!config) {
        const createResponse = await authFetch(
          configPath({
            integration: props.integration,
            componentKey: undefined,
            id: undefined,
          }),
          // TODO: root config
          { method: "POST", json: {} }
        );
        if (!createResponse) return;
      }
      const response = await authFetch(configPath({ ...props, id: item.id }), {
        method: "PUT",
        json: omit(item, "id"),
      });
      if (response?.ok) {
        navigate(`../${item.id}`, { relative: "path" });
      }
    },
    [authFetch, config, navigate, props]
  );

  const idInstaller = {
    items: { id: installer?.[componentKey] ?? ({} as any) },
  } as FrontendComponentInstaller<any>;

  return (
    <>
      <Typography.Title level={5}>Installing a new {itemKind}</Typography.Title>
      <Typography.Paragraph>
        {component.type === "string" ? "Enter" : "Select"} the identifier of the{" "}
        {itemKind} to install, then click &quot;Next&quot;:
      </Typography.Paragraph>
      <ConfigForm
        config={{}}
        context={context}
        id={NEW_SENTINEL}
        installer={idInstaller}
        isFetching={props.isFetching}
        schema={schema}
        setConfig={submit}
        step="new"
      />
    </>
  );
};
