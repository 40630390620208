import { Alert } from "antd";
import {
  GraphVisualization,
  NodeFlowRenderer,
} from "components/Assessment/components/GraphVisualization";
import { VerticalSpacedDiv } from "components/divs";
import dagre from "dagre";
import { compact, uniqBy } from "lodash";
import { ReactNode, useMemo } from "react";
import { DiscoverMatch } from "shared/graph/discover";
import { keyOf } from "shared/graph/graph";
import { ConnectedNode, Node } from "shared/graph/types";
import { DirectedGraph } from "shared/graph/types";

/** Maximum number of "show" nodes to display */
const RESULT_LIMIT = 10;
/** Maximum number of paths to display per "show" node
 *
 * The total number of paths is limited to
 * `RESULT_LIMIT * PATH_LIMIT`
 */
const PATH_LIMIT = 20;

export type DiscoverVisualizationProps<G extends object> = {
  matches: DiscoverMatch<DirectedGraph<G>>[];
  renderer: NodeFlowRenderer<G>;
  titler: (node: Node<G, keyof G>) => ReactNode;
};

/** Renders a graph visualization of queried paths
 *
 * At most {@link RESULT_LIMIT} "show" nodes will be displayed;
 * for each "show" node, at most {@link PATH_LIMIT} paths will be
 * displayed.
 */
export const DiscoverVisualization = <G extends object>(
  props: DiscoverVisualizationProps<G>
) => {
  const { matches, renderer, titler } = props;

  const { nodes, edges, warnings } = useMemo(() => {
    const before = performance.now();
    const resultLimitWarning =
      matches.length > RESULT_LIMIT
        ? `Query returned ${matches.length} results, of which the first ${RESULT_LIMIT} are shown.`
        : null;
    let pathLimitWarning: string | null = null;
    const limited = matches.slice(0, RESULT_LIMIT);
    const nodes: ConnectedNode<G, keyof G>[] = [];
    const edges: [Node<G, keyof G>, Node<G, keyof G>][] = [];
    for (const n of limited) {
      nodes.push(n.node);
      for (const m of n.matches) {
        if (m.paths.length > PATH_LIMIT) {
          pathLimitWarning = `Query returned one or more paths that were truncated to the first ${PATH_LIMIT} nodes.`;
        }
        for (const p of m.paths.slice(0, PATH_LIMIT)) {
          let s = p[0];
          nodes.push(s);
          for (const n of p.slice(1)) {
            nodes.push(n);
            edges.push([s, n]);
            s = n;
          }
        }
      }
    }
    const uniqueNodes = uniqBy(nodes, (n) => keyOf(n));
    const uniqueEdges = uniqBy(edges, (e) => e.map(keyOf));
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ nodesep: 0, edgesep: 0, rankdir: "LR", ranksep: 0 });
    for (const node of uniqueNodes) {
      dagreGraph.setNode(keyOf(node), { width: 1, height: 1 });
    }
    for (const [parent, child] of uniqueEdges) {
      dagreGraph.setEdge(keyOf(parent), keyOf(child));
    }
    dagre.layout(dagreGraph);
    let maxX = 0,
      maxY = 0;
    for (const n of uniqueNodes) {
      const d = dagreGraph.node(keyOf(n));
      maxX = Math.max(d.x, maxX);
      maxY = Math.max(d.y, maxY);
    }
    const flowNodes = uniqueNodes.map((n) => {
      const d = dagreGraph.node(keyOf(n));
      return {
        inner: n,
        display: "inner" as const,
        position: { d: 0, x: d.x - maxX / 2, y: d.y - maxY / 2 },
      };
    });
    const flowEdges = uniqueEdges.map(([parent, child]) => ({ parent, child }));
    const after = performance.now();
    /* eslint-disable no-console */
    console.log(
      "Time to construct graph visualization",
      (after - before).toFixed(1),
      "ms"
    );
    console.log("  nodes:", flowNodes.length, "edges:", flowEdges.length);
    /* eslint-enable no-console */
    return {
      nodes: flowNodes,
      edges: flowEdges,
      warnings: compact([resultLimitWarning, pathLimitWarning]),
    };
  }, [matches]);

  return (
    <VerticalSpacedDiv>
      {warnings.length ? (
        <Alert
          type="warning"
          message={warnings.join("\n")}
          style={{ maxWidth: "1000px" }}
        />
      ) : null}
      <GraphVisualization
        edges={edges}
        nodes={nodes}
        renderer={renderer}
        titler={titler}
      />
    </VerticalSpacedDiv>
  );
};
