import { AppRoutes } from "components/App/routeConstants";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { useContext } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Node } from "shared/graph/types";
import { AssessmentNodes, TargetNodeType } from "shared/types/assessment/data";

export const NodeLink: React.FC<{
  node: Node<AssessmentNodes, TargetNodeType>;
}> = ({ node }) => {
  const { orgSlug, assessmentId } = useParams();
  const { scopeKey } = useContext(ScopeContext);
  return (
    <Link
      to={`/o/${orgSlug}/${AppRoutes.IamAssessment}/${assessmentId}/explore/${
        node.type
      }/${encodeURIComponent(node.key)}?show=${
        node.type
      }&where=${encodeURIComponent(
        `${node.type}="${node.key}"`
      )}&scope=${encodeURIComponent(scopeKey)}`}
    >
      view
    </Link>
  );
};
