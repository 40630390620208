import { Node } from "../../graph/types";
import { AssessmentNodes, GrantNode, PrincipalNode } from "./data";

export type FindingNode = Pick<
  Node<AssessmentNodes, keyof AssessmentNodes>,
  "key" | "type"
>;

export type AggregatedFindingNode = GrantNode | PrincipalNode;

export const FindingState = ["ignored", "open", "resolved"] as const;
export type FindingState = (typeof FindingState)[number];

export type FindingTriggerType = "fix" | "new" | "regression";

/** Records an instance of a monitor detection on a specific assessment job */
export type Detection = {
  monitorId: string;
  node: FindingNode;
};

export type FindingRemediation = {
  link: string;
} & GitHubRemediation;

export type FindingIssue = {
  link: string;
} & JiraIssue;

type JiraIssue = {
  id: string;
  integration: "jira";
  key: string;
};
type GitHubRemediation = {
  prNumber: number;
};

/** Tracks all detections for a monitor on an assessment */
export type Finding = Detection & {
  assessmentId: string;
  scopeKey: string;
  /** Finding ID is deterministically retrievable from detection data */
  findingId: string; // Mirror document ID for searching
  /** Human ID is monotonically incrementing */
  humanId: number;
  /** The current state of this finding: ignored, open, or resolved
   *
   * - "ignored" findings are ignored regardless of wether or not they are currently detected
   * - "open" findings are not ignored, and are currently detected
   * - "resolved" findings are not ignored, were previously detected, and not currently detected
   */
  state: FindingState;
  /** Records when and by whom this finding was ignored */
  ignore?: {
    at: number;
    by: string;
    justification?: string;
  };
  /** Records the most recent change to this finding
   *
   * - "fix" triggers record a job where state transitions from open -> resolved
   * - "new" triggers record the job that created this finding
   * - "regression" triggers record a job where state transitions from resolved -> open
   */
  trigger: { type: FindingTriggerType; jobId: string; at: number };
  /** Records all jobs that had a detection for this finding
   *
   * For rendering histograms.
   */
  history: {
    jobId: string;
    at: number;
  }[];
  archived?: boolean;
  /** Details of any issue created in issue-tracking software */
  issue?: FindingIssue;
  /** Assigned owner of the finding, if one exists */
  assignment?: {
    assignee: string;
    reason: string;
    /** Status of assignment job, if started */
    status: "assigning" | "complete" | "remediating" | "tracking";
  };
  /* Details of any remediation created in a version control system */
  remediation?: FindingRemediation;
};
