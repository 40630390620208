import { capitalize } from "lodash";
import pluralize from "pluralize";

import { AssessmentScope } from "../types/assessment";
import { PrincipalType, TargetNodeTypes } from "../types/assessment/data";
import { Finding } from "../types/assessment/finding";
import { MonitorPriority } from "../types/assessment/monitor";
import { AppContext, appHost } from "../types/environment";

export const ALL_SCOPE_SENTINEL = "all";

export const ASSESSMENT_TARGET_REGEX = /^[\w\-_.\s]+$/;

export const AWS_RESOURCE_ARN = /arn:.*?:([^:]+):.*?:.*?:((.+?)[/:])?(.*)/;

export const integrationTitles = {
  aws: {
    permissionSet: "Policy statement",
    permissions: "Actions",
  },
  gcloud: {
    permissionSet: "Role",
    permissions: "Permissions",
  },
};

export const IamShowOptions = TargetNodeTypes.map((t) => ({
  value: t,
  label: capitalize(pluralize(t)),
}));

export const memberTypeToLabel = (
  integration: AssessmentScope["integration"]
): Record<PrincipalType, string> => ({
  domain: "Everyone in a domain",
  "federated-identity": "Federation Role",
  group: "Directory Group",
  "iam-group": "IAM Group",
  "iam-user": "IAM User",
  "logged-in": "Every authenticated user on the Internet",
  public: "Everyone on the Internet",
  "service-account": integration === "aws" ? "IAM Role" : "Service Account",
  unknown: "(unknown principal type)",
  user: "Directory User",
});

export const toLink = (
  { environment }: AppContext,
  orgSlug: string,
  finding: Finding
) =>
  `${appHost(environment)}/o/${orgSlug}/iam-assessments/${String(
    finding.assessmentId
  )}/monitors/${encodeURIComponent(finding.monitorId)}/findings/${String(
    finding.findingId
  )}?scope=${encodeURIComponent(finding.scopeKey)}`;

export const MonitorRanking: Record<MonitorPriority, number> = {
  CRITICAL: 0,
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
};
export const priorityLabels: Record<MonitorPriority, string> = {
  CRITICAL: "Urgent",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};
