import { SnowflakeInstaller } from "components/Integrations/Snowflake/install/types";
import { DirectoryGroup } from "shared/types/directory";

import { snowflakeIamWriteInstaller } from "./iam-write";

export const snowflakeInstaller = (
  groups?: DirectoryGroup[]
): SnowflakeInstaller => ({
  base: {
    items: {},
  },
  "iam-write": snowflakeIamWriteInstaller(groups),
});
