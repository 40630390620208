import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Dropdown, message } from "antd";
import { DropdownButtonType } from "antd/lib/dropdown";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

type ExportOption<T> = {
  label: ReactNode;
  buttonType?: DropdownButtonType;
  icon?: React.ReactNode;
  blob: (data: T[]) => Promise<string>;
  extension: string;
};

export const Export = <T,>({
  data,
  filename,
  options,
  title,
}: {
  data: T[];
  filename: string;
  options: Record<string, ExportOption<T>>;
  title?: string;
}) => {
  const anchorRef = useRef<HTMLAnchorElement | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const [downloadName, setDownloadName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = useCallback(
    async (selected?: { key: string }) => {
      if (!data?.length || !selected) {
        // Export button shouldn't even render, just being defensive here.
        message.warn("Nothing to export");
        return;
      }
      // Must clear URL in order for new downloads to trigger
      setDownloadUrl(undefined);
      setLoading(true);
      try {
        const option = options[selected.key];
        const blob = await option.blob(data);
        const url = URL.createObjectURL(new Blob([blob]));
        setDownloadUrl(url);
        setDownloadName(`${filename}.${option.extension}`);
        setLoading(false);
      } catch (e) {
        console.error(e);
        message.error("Error exporting ");
        setLoading(false);
      }
    },
    [data, filename, options]
  );

  const menuProps = useMemo(
    () => ({
      items: Object.entries(options).map(([key, { label }]) => ({
        key,
        label: `Export as ${label}`,
      })),
      onClick: handleExport,
    }),
    [handleExport, options]
  );

  // We have to click the hidden anchor inside a useEffectHook to ensure that
  // the setDownloadUrl state update completed and the anchor will download the new data.
  // Otherwise the first click on the button downloads an empty blob - the null state.
  useEffect(() => {
    if (downloadUrl) anchorRef.current?.click();
  }, [downloadUrl]);

  return (
    <>
      <Dropdown menu={menuProps} disabled={loading || !data?.length}>
        <Button icon={loading ? <LoadingOutlined /> : <ExportOutlined />}>
          {title}
        </Button>
      </Dropdown>
      <a hidden href={downloadUrl} download={downloadName} ref={anchorRef}>
        Click to download
      </a>
    </>
  );
};
