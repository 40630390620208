import { Alert, Button, Col, Row, Select, Spin, Typography } from "antd";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  MsTeamsApi,
  MsTeamsConfig,
} from "shared/integrations/notifiers/ms-teams/types";
import { IntegrationConfig } from "shared/integrations/shared";

import { useFirestoreDoc } from "../../../providers/FirestoreProvider";
import { ClientConfigError } from "../../../shared/integrations/notifiers/slack/types";
import { Tenant } from "../../Login/util";
import { OAuth2IntegrationCard } from "../IntegrationCard";
import { TeamsLogo } from "./TeamsLogo";

interface ChanelSelectorProps {
  channels: MsTeamsApi.Channel[];
  channelId: string | undefined;
  handleSetChannel: (value: string) => void;
  teamId: string | undefined;
  fetching: boolean;
  loading: boolean;
}

const ChannelSelector: React.FC<ChanelSelectorProps> = ({
  channels,
  channelId,
  handleSetChannel,
  teamId,
  fetching,
  loading,
}) => (
  <Select
    options={channels.map((channel) => ({
      label: channel.displayName,
      value: channel.id,
    }))}
    value={channelId}
    onChange={handleSetChannel}
    placeholder={teamId ? "Select a channel" : "Select a team first"}
    disabled={!teamId || fetching || loading}
    style={{ minWidth: "12em" }}
  />
);

export const Teams: React.FC = () => {
  const tenantId = useContext(Tenant);
  const path = `o/${tenantId}/integrations/ms-teams`;
  const config = useFirestoreDoc<IntegrationConfig & MsTeamsConfig>(path, {
    live: true,
  });

  const clientError = useFirestoreDoc<ClientConfigError>(
    `${path}/client_errors/latest`,
    {
      live: true,
    }
  );

  const [complete, setComplete] = useState(false);
  const [teams, setTeams] = useState<MsTeamsApi.Team[]>([]);
  const [channels, setChannels] = useState<MsTeamsApi.Channel[]>([]);
  const [teamId, setTeamId] = useState<string | undefined>(undefined);
  const [channelId, setChannelId] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState(false);
  const [showSwitchButton, setShowSwitchButton] = useState(false);

  const fetch = useAuthFetch(setError, setFetching);

  const msTeamsTenantId = useMemo(
    () => config.doc?.data?.msTeamsTenantId,
    [config.doc]
  );
  const isInstalled = useMemo(
    () => config.doc?.data?.state === "installed",
    [config.doc?.data?.state]
  );
  const errorMessage = useMemo(
    () => error ?? clientError?.doc?.data?.message,
    [error, clientError]
  );
  const loading = useMemo(
    () => config.loading || clientError.loading || fetching,
    [clientError.loading, config.loading, fetching]
  );

  useGuardedEffect(
    async () => {
      if (msTeamsTenantId && isInstalled) {
        const response = await fetch(`integrations/ms-teams/teams`, {});
        if (!response || !response.ok) return;
        const data = await response.json();
        setTeams(data.teams);
      }
    },
    setError,
    [msTeamsTenantId, fetch, isInstalled]
  );

  useGuardedEffect(
    async () => {
      if (msTeamsTenantId && isInstalled && teamId) {
        const response = await fetch(
          `integrations/ms-teams/teams/${teamId}/channels`,
          {}
        );
        if (!response || !response.ok) return;
        const data = await response.json();
        setChannels(data.channels);
      }
    },
    setError,
    [msTeamsTenantId, fetch, teamId, isInstalled]
  );

  useGuardedEffect(
    async () => {
      const data = config.doc?.data;
      if (data && data.appId && data.channelId && data.teamId && isInstalled) {
        setChannelId(data.channelId);
        setTeamId(data.teamId);
        setComplete(true);
      }
    },
    setError,
    [config.doc?.data, fetch, teamId, isInstalled]
  );

  useEffect(() => {
    setShowSwitchButton(
      config.doc?.data && config.doc?.data?.channelId !== channelId
    );
  }, [channelId, config.doc]);

  const handleSubmit = useCallback(async () => {
    await fetch(`integrations/ms-teams/connection`, {
      method: "POST",
      json: {
        teamId,
        channelId,
      },
    });
  }, [teamId, channelId, fetch]);

  const handleSetChannel = useCallback(
    (channelId: string) => {
      setChannelId(channelId);
    },
    [setChannelId]
  );

  const handleSetTeam = useCallback(
    (teamId: string) => {
      setTeamId(teamId);
      setChannelId(undefined);
    },
    [setTeamId, setChannelId]
  );

  const handleRemove = useCallback(async () => {
    await fetch(`integrations/ms-teams/connection`, {
      method: "DELETE",
    });
    setComplete(false);
    setError(undefined);
    setTeamId(undefined);
    setChannelId(undefined);
    setTeams([]);
    setChannels([]);
  }, [fetch]);

  return (
    <OAuth2IntegrationCard
      mode="consent"
      title="Microsoft Teams"
      integration="ms-teams"
      integrationDoc={config.doc}
      logo={<TeamsLogo />}
      onRemove={msTeamsTenantId ? handleRemove : undefined}
    >
      {loading ? (
        <Spin />
      ) : errorMessage ? (
        <Alert type="error" message={errorMessage} />
      ) : complete ? (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Typography.Text>
              <b>Connected</b>
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>
              <b>Team:</b>{" "}
              {teams.find((t) => t.id === teamId)?.displayName ?? teamId}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>
              <b>Channel:</b>{" "}
            </Typography.Text>
            <ChannelSelector
              channels={channels}
              channelId={channelId}
              handleSetChannel={handleSetChannel}
              teamId={teamId}
              fetching={fetching}
              loading={loading}
            />
          </Col>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!showSwitchButton || !teamId || !channelId}
            loading={fetching}
            style={{ marginTop: "1em" }}
          >
            Switch Channel
          </Button>
        </Row>
      ) : (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div>
              <Typography.Text>
                <b>Team</b>
              </Typography.Text>
            </div>
            <Select
              options={teams.map((team) => ({
                label: team.displayName,
                value: team.id,
              }))}
              value={teamId}
              onChange={handleSetTeam}
              placeholder="Select a team"
              disabled={fetching || loading}
              style={{ minWidth: "12em" }}
            />
          </Col>
          <Col span={24}>
            <div>
              <Typography.Text>
                <b>Channel</b>
              </Typography.Text>
            </div>
            <ChannelSelector
              channels={channels}
              channelId={channelId}
              handleSetChannel={handleSetChannel}
              teamId={teamId}
              fetching={fetching}
              loading={loading}
            />
          </Col>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!teamId || !channelId}
            loading={fetching}
            style={{ marginTop: "1em" }}
          >
            Connect
          </Button>
        </Row>
      )}
    </OAuth2IntegrationCard>
  );
};

export { TeamsLogo };
