import { ClipDiv } from "components/divs";
import { ResourceNode } from "shared/types/assessment/data";

import { ExportColumnType } from "../../AssessmentExport";
import { Resource } from "../../cells/Resource";
import { genericExport } from "../../export";
import { stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";

export const resourceColumns = (
  props: AssessmentColumnProps
): ExportColumnType<ResourceNode>[] => [
  {
    key: "resource",
    title: "Resource",
    sorter: (left, right) => stringSorter(left.key, right.key),
    render: ({ key }) => {
      return (
        key && (
          <>
            <ClipDiv>
              <Resource resource={key} {...props} />
            </ClipDiv>
          </>
        )
      );
    },
    export: genericExport((node) => node.key),
  },
];
