import stringify from "json-stable-stringify";
import { findIndex } from "lodash";

import { mapWith } from "../util/collections";
import { Bitset } from "./bitset";
import { DigramBitset, digrams } from "./digram";
import { DslMapping } from "./dsl";
import { dfs } from "./graph";
import {
  ConnectedNode,
  DirectedGraph,
  Direction,
  Directions,
  EmptyBoost,
  GraphOf,
  Node,
  Path,
} from "./types";

export type NodePredicate<G extends object> = (
  node: Node<G, keyof G>
) => boolean;

export type NodeSearch<G extends object> = {
  /** The search term for this node match
   *
   * E.g. `keyword` or `type:keyword`, but not `via:->keyword`
   */
  term: string;
  /** The keyword for this node match
   *
   * The keyword is only the text component of the match, and
   * excludes all control characters.
   *
   * Used for search boost only; if this is incorrect then
   * boosted searches may falsely return negative matches.
   */
  keyword: string;
  /** If included in term, the key type for this node match
   *
   * The key type is the value of any type constraint in the term.
   *
   * Used for search boost only; if this is incorrect then
   * boosted searches may falsely return negative matches.
   */
  keytype: string | undefined; // casing to match "keyword"
  /** The predicate function matching a path end node */
  predicate: NodePredicate<G>;
  /** Predicates for matching required intermediate nodes
   *
   * Note that search ignores ordering.
   */
  via: NodePredicate<G>[];
  /** Whether to invert this match
   *
   * If true, search will return paths that do not match
   * search.
   */
  invert?: boolean;
  /** If true, will stop on a missed type match */
  abortOnTypeMismatch?: boolean;
  /** Query parse warnings */
  warnings?: string[];
};

/** Node search that matches all nodes */
export const TRUE_SEARCH: NodeSearch<any> = {
  predicate: () => true,
  term: "",
  keyword: "",
  keytype: undefined,
  via: [],
};

/** Adds type search boost to a graph */
export const addTypeBoost = <G extends object>(graph: DirectedGraph<G>) => {
  for (const direction of Directions) {
    dfs(graph, direction, {
      init: (node) => {
        const memo = new Set<symbol>();
        memo.add(Symbol.for(node.type as string));
        return memo;
      },
      combine: (left, right) => {
        const memo = new Set(left.values());
        for (const symbol of right.values()) {
          memo.add(symbol);
        }
        return memo;
      },
      finalize: (node, memo) => {
        // Do not override keyword boost
        node.boost = node.boost ?? EmptyBoost();
        node.boost[direction].types = memo;
      },
    });
  }
};

/** Adds digram search boost to a graph */
export const addDigrams = <G extends object>(
  graph: DirectedGraph<G>,
  mapping: DslMapping<G>
) => {
  for (const direction of Directions) {
    dfs(graph, direction, {
      init: (node) => {
        const mapped = mapping[node.type];
        const keyTerms = mapped?.keys?.(node) ?? [node.key];
        const attributeTerms = mapped?.attributes
          ? mapWith(mapped.attributes, function* ([_, a]) {
              for (const v of a(node)) yield v;
            })
          : [];
        const terms = [...keyTerms, ...attributeTerms];
        return DigramBitset(terms);
      },
      combine: Bitset.merge,
      finalize: (node, memo) => {
        // Do not override keytype boost
        node.boost = node.boost ?? EmptyBoost();
        node.boost[direction].digrams = memo;
      },
    });
  }
};

/** Returns subgraph matching a reachability criterion
 *
 * Returns all nodes that match the `from` predicate, that
 * are connected to at least one node that matches the `to` predicate.
 *
 * May optionally search with an intermediate constraint, requiring
 * paths to traverse through matching nodes.
 *
 * In graph-database query languages this performs
 *
 *   SELECT (from)->(via)->(to)
 *   WHERE ...
 *
 * Where each node is subject to its respective predicate. Unlike a graph
 * database, this only returns the `from` nodes, rather than the matching
 * paths. (This is done as a performance optimization).
 *
 * If the `invert` parameter is passed, will instead only return `from` nodes
 * where the `to` nodes are unreachable.
 */
// Works by first filtering graph to nodes matching left predicate. Then
// performs a DFS for each of those starting nodes. DFS results are cached
// on a per-vertex basis, allowing for lower algorithmic complexity.
export const reachable = <D extends DirectedGraph<any>>(
  graph: D,
  from: NodePredicate<GraphOf<D>>,
  query: NodeSearch<GraphOf<D>>
): D =>
  ({
    nodes: paths(graph, from, query, { findFirst: true }).map(
      ({ node }) => node
    ),
  } as D);

type Match<D extends DirectedGraph<any>> = {
  node: ConnectedNode<GraphOf<D>, keyof GraphOf<D>>;
  paths: Path<GraphOf<D>>[];
};

/** Returns all nodes matching a reachability criterion, together with
 *  all matching paths.
 *
 * Paths are found on a directed graph subject to two predicates, "target"
 * and "to", that define path starts and ends. Paths are directed from
 * start to end, as follows:
 *
 *   "target" -> ... -> ... -> "to"
 *
 * By default, will also return all "to" nodes that reach the "target" node:
 *
 *   "to" -> ... -> ... -> "target"
 *
 * In addition, one or more "via" predicates can be attached; in this case paths
 * must traverse through nodes matching these predicates:
 *
 *   "target" -> ... -> "via" -> ... -> "to"
 *
 * If "invert" is true, the returned paths are all paths descending from
 * "from"-matching nodes that have no path reaching the "to"
 * predicate.
 *
 * If "findFirst" is true, will only return the first matching (or not matching)
 * path, for each "from" node.
 */
export const paths = <D extends DirectedGraph<any>>(
  /** The graph */
  graph: D,
  /** The predicate matching all path starting nodes */
  target: NodePredicate<GraphOf<D>>,
  /** The predicate matching all (or not matching all if "invert") path ending nodes */
  query: NodeSearch<GraphOf<D>>,
  /** Path-search options */
  options?: {
    /** If true, only returns the first matching (or not matching if "invert") path */
    findFirst?: boolean;
    /** Valid directions to search for query matches from the targets */
    directions?: readonly Direction[];
  }
): Match<D>[] => {
  const directions = options?.directions ?? Directions;
  const fromNodes = graph.nodes.filter(target);
  const known: Record<string, [boolean, Path<GraphOf<D>>][]> = {};
  const keywordDigrams = query.keyword ? digrams(query.keyword) : undefined;
  const keytypeSymbol = query.keytype ? Symbol.for(query.keytype) : undefined;
  // Record via matches in a bitset; note max 31 via items
  const constraintReq = 2 ** query.via.length - 1;
  // Visit time is O(n)
  function* traverse(
    current: ConnectedNode<GraphOf<D>, keyof GraphOf<D>>,
    direction: Direction,
    met: number,
    visited: Set<string>
  ): Generator<[boolean, Path<GraphOf<D>>]> {
    const key = stringify({
      key: current.key,
      type: current.type,
      met,
      direction,
    });
    const boost = current.boost?.[direction];

    // Performance optimization: if we've already proved that this graph path
    // either does or does not match, return result. This reduces visit time
    // from O(n^2) to O(n). Note that the key must include the "via" constraint
    // value, otherwise previous evaluations may pollute this value.
    if (known[key]) {
      for (const result of known[key]) {
        yield result;
      }
      return;
    }

    // Avoid cycles: we've already returned paths for this node
    if (visited.has(key)) {
      return;
    }
    visited.add(key);

    // Performs the via match; needs to be prior to node matching code in order
    // to filter when via matches the left node
    const constraintIx = findIndex(query.via, (v) => v(current));
    const nextConstraintEval =
      met | (constraintIx >= 0 ? 1 << constraintIx : 0);

    // If all via constraints match and the end constraint matches, this path matches;
    // return match now
    if (query.predicate(current) && nextConstraintEval === constraintReq) {
      known[key] = [[true, [current]]];
      yield [true, [current]];
      return;
    }

    if (
      // If this is a leaf node, this path does not match
      current[direction].length === 0 ||
      // Or if type matches, and we abort on type keyword mismatches
      (!!query.keytype &&
        current.type === query.keytype &&
        query.abortOnTypeMismatch) ||
      // Or if type query does not appear in any children
      (keytypeSymbol && boost?.types && !boost.types.has(keytypeSymbol)) ||
      // Or if keyword does not appear in any children
      (keywordDigrams &&
        boost?.digrams &&
        !boost.digrams.hasAll(keywordDigrams))
    ) {
      known[key] = [[false, [current]]];
      yield [false, [current]];
      return;
    }

    // Otherwise DFS
    for (const node of current[direction]) {
      known[key] = [];
      for (const [isMatch, path] of traverse(
        node,
        direction,
        nextConstraintEval,
        visited
      )) {
        const value: [boolean, Path<GraphOf<D>>] = [
          isMatch,
          direction === "children" ? [current, ...path] : [...path, current],
        ];
        known[key].push(value);
        yield value;
        // Abort early if we only want one matching path
        if (options?.findFirst && isMatch) return;
      }
    }
  }

  const out: Match<D>[] = [];
  for (const node of fromNodes) {
    const paths: Path<GraphOf<D>>[] = [];
    let hasMatch = false;
    for (const direction of directions) {
      for (const [isMatch, path] of traverse(node, direction, 0, new Set())) {
        hasMatch ||= isMatch;
        if (isMatch !== !!query.invert) {
          paths.push(path);
        }
      }
    }
    if (hasMatch !== !!query.invert && paths.length > 0) {
      out.push({ node, paths });
    }
  }
  return out;
};
