import { Reducers } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import { GrantAggregates } from "../../types/assessment/data/grant";
import { keyAggregate, permissionAggregate } from "./shared";

export const grantReducers: Reducers<AssessmentNodes, GrantAggregates> = {
  permissions: permissionAggregate,
  principals: keyAggregate("principal"),
  risks: keyAggregate("risk"),
};
