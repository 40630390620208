import { Export } from "components/Export";
import { Dictionary } from "lodash";
import { useContext, useMemo } from "react";
import { Finding } from "shared/types/assessment/finding";

import { Monitor } from "../../../shared/types/assessment/monitor";
import { FindingsContext } from "../contexts/FindingsContext";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";

// TODO: Add explanation for each finding (viz, list critical unused permissions, etc.)
const toTsv = (monitors: Dictionary<Monitor>) => async (findings: Finding[]) =>
  [
    "monitor\tscope\tshow\tkey",
    ...findings.map(
      (f) =>
        `${monitors[f.monitorId].label}\t${f.scopeKey}\t${f.node.type}\t${
          f.node.key
        }`
    ),
  ].join("\n");

const toJson = async (findings: Finding[]) =>
  JSON.stringify(findings, undefined, 2);

export const FindingsExport: React.FC = () => {
  const { assessment } = useContext(SelectedAssessmentContext);
  const { allMonitors, prioritized } = useContext(FindingsContext);
  const { scopeKey } = useContext(ScopeContext);

  const makeTsv = useMemo(() => toTsv(allMonitors), [allMonitors]);

  const collapsed = useMemo(
    () => prioritized.flatMap((m) => m.scopedFindings.map((f) => f.data)),
    [prioritized]
  );

  return assessment.doc ? (
    <Export
      data={collapsed}
      filename={`${assessment.doc.data.name}-findings-${scopeKey}`}
      options={{
        json: { label: "JSON", blob: toJson, extension: "json" },
        tsv: { label: "TSV", blob: makeTsv, extension: "tsv" },
      }}
      title="Export"
    />
  ) : null;
};
