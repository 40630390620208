import { Descriptions, List } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { sortBy } from "lodash";
import { useContext } from "react";
import { integrationTitles } from "shared/assessment/constants";
import { grantNodeToPermissionSet } from "shared/assessment/render";
import { GrantNode, NodeFor } from "shared/types/assessment/data";

import { ActionListDisplay } from "../../Action";
import { ConditionDisplay } from "../../cells/Condition";
import { NodeLink } from "../../cells/NodeLink";
import { RiskGroupedPermissionList } from "../../cells/PermissionAggregate";
import {
  PrincipalCell,
  PrincipalExternalLink,
  PrincipalLink,
} from "../../cells/Principal";
import { Resource } from "../../cells/Resource";
import { NodeDescriptions } from "../NodeDescriptions";
import { TrustPolicyDisplay } from "../principal/PrincipalNode";

const { Item } = Descriptions;

export const GrantNodeDisplay: React.FC<{
  node: GrantNode;
}> = ({ node }) => {
  const { integration } = useContext(ScopeContext);

  return (
    <>
      <NodeDescriptions>
        <Item label="Principal">
          <PrincipalExternalLink data={node.data} id={node.data.principal} />
        </Item>
        <Item
          label={
            integration
              ? integrationTitles[integration]["permissionSet"]
              : "Role"
          }
        >
          {grantNodeToPermissionSet(node)}
        </Item>
        <Item label="Resource">
          <NodeLink
            node={
              {
                key: node.data.resource,
                type: "resource",
              } as NodeFor<"resource">
            }
          />
          &nbsp;
          <Resource resource={node.data.resource} />
        </Item>
        {node.data.condition && (
          <Item label="Condition">
            <ConditionDisplay condition={node.data.condition} />
          </Item>
        )}
        {node.data.actions && (
          <Item label="Actions">
            <ActionListDisplay actions={node.data.actions} />
          </Item>
        )}
        {integration && (
          <Item label="Risks">
            <RiskGroupedPermissionList
              permissions={node.aggregates.permissions}
              integration={integration}
              showControl
            />
          </Item>
        )}
        <Item label="Accessible by">
          <List
            style={{
              maxHeight: "280px",
              paddingRight: "15px",
              overflowY: "auto",
            }}
          >
            {sortBy(node.aggregates.principals, ["principalType", "id"]).map(
              (p, ix) => (
                <List.Item key={ix}>
                  <PrincipalLink data={p} type="short" show="grant" />{" "}
                  <PrincipalCell principalData={p} />
                </List.Item>
              )
            )}
          </List>
        </Item>
      </NodeDescriptions>

      {node.data.trustedPrincipals ? (
        <TrustPolicyDisplay
          trustedPrincipals={node.data.trustedPrincipals}
        ></TrustPolicyDisplay>
      ) : null}
    </>
  );
};
