import { compact, size } from "lodash";

import { StateError } from "../types/error";
import { NodePredicate, NodeSearch } from "./search";
import { Node } from "./types";

const WHITESPACE = /\s/;
const EXACT = /"(.*)"/;

// Represents [type(=|:)][attribute:][!]term where brackets indicate optionality
const DSL_PATTERN = /([^"]*?[^\\][=:])?([^"]*?[^\\]:)?(!?)(.*)/;

type NodeMapping<G extends object, K extends keyof G> = {
  /** Values used to compare against `term` or `type:term` searches */
  keys?: (node: Node<G, K>) => string[];
  /** Mapping used to evaluate `type:attribute:term` searches */
  attributes?: Record<string, (node: Node<G, K>) => string[]>;
};

type DslAliases = Partial<Record<string, string>>;

/** Mapping of possible types and their associated attributes
 *
 * If a type is in the mapping with an empty attribute list, it may be used for
 * a type query with no allowed attributes. If a type is not in the mapping,
 * it may not be used for a type query.
 */
type DslTypes = Record<string, string[]>;

export type DslMapping<G extends object> = {
  [K in keyof G]?: NodeMapping<G, K>;
};

const dealias = (aliases: DslAliases, term: string) => {
  const found = [...Object.keys(aliases)].find((k) => term.startsWith(k));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return found ? term.replace(found, aliases[found]!) : undefined;
};

// Implements the "termMatch" portion of the DSL
const predicate = <G extends object>(
  mapping: DslMapping<G>,
  term: string,
  types: DslTypes
) => {
  const match = term.match(DSL_PATTERN);
  if (!match) {
    throw new StateError({ term }, "Could not parse search term");
  }
  const [_, typeTerm, attributeTerm, invert, keyword] = match;
  // Remove dangling ":"
  const type = typeTerm?.slice(0, typeTerm.length - 1);
  const warnings =
    type && !(type in types)
      ? [
          `Type term '${type}:' is not valid. Valid types are: ${Object.keys(
            types
          )
            .sort()
            .join(", ")}.`,
        ]
      : [];
  const attribute = attributeTerm?.slice(0, attributeTerm.length - 1);
  const typeMap = mapping[type as keyof G];
  const attributesFor = attribute
    ? typeMap?.attributes?.[attribute]
    : undefined;
  if (attribute && type && !types[type].includes(attribute) && !attributesFor) {
    warnings.push(
      `Attribute term '${type}:${attribute}:' is not valid. ${
        size(types[type])
          ? `Valid attributes for '${type}' are: ${types[type]
              .sort()
              .join(", ")}.`
          : `Type '${type}' has no attributes.`
      }
        `
    );
  }
  const exactMatch = keyword.match(EXACT);
  return {
    // No boost for "!keyword" searches to avoid improper removal
    keyword: invert ? "" : exactMatch ? exactMatch[1] : keyword,
    keytype: type,
    abortOnTypeMismatch: typeTerm?.at(-1) === "=",
    predicate: (node: Node<G, keyof G>) => {
      const nodeData = node.data as any;
      if (type && node.type !== type) return false;
      const values = attribute
        ? compact(attributesFor?.(node) ?? [nodeData[attribute]]).map(String)
        : typeMap?.keys?.(node) ?? [node.key];
      const match = !!values?.find((v) =>
        exactMatch ? v === exactMatch[1] : v?.includes(keyword)
      );
      return match !== !!invert;
    },
    warnings,
  };
};

/** Parses a graph search DSL
 *
 * DSL:
 * Produces a preset that finds all grant nodes that can reach:
 *   term - A node containing term
 *   !term - FA node that does not contain term
 *   "term" - A node exactly matching term
 *   !"term" - A node that does not exactly match term
 *   type:{termMatch} - A node that matches type with term match obeying above rules
 *   type: - A node that matches type
 *   type:attribute:{termMatch} - A node that type type and whose attribute matches term-match rules
 *   {via}->{node} - A node matching {node} term, reachable via {via} term
 * Can also construct a preset that finds all grant nodes that can not reach matches,
 * using ^{...}
 *
 * For each node, term will match the node key, unless the node type appears
 * in the `mapping` parameter, in which case the node's string value will be
 * determined from the mapping value for that node's type.
 *
 * Aliases will string substitute term matches.
 *
 * Example:
 *
 *    ^usage:type:!"unused"->permission:
 *
 * finds all grants that can not reach a used or unknown permission (this is the expression
 * for the "Unused Grants" preset) when the assessment DSL mapping is used.
 *
 */
export const parse = <G extends object>(
  mapping: DslMapping<G>,
  aliases: DslAliases,
  types: DslTypes
) => {
  const viaDealias = (input: string): string[] =>
    input.split("->").flatMap((s) => {
      const xform = dealias(aliases, s);
      return xform ? viaDealias(xform) : [s];
    });

  return (search: string): NodeSearch<G>[] => {
    const trimmed = search.trim();
    if (!trimmed) return [];
    const terms = search.trim().split(WHITESPACE);
    return terms.map((fullTerm) => {
      let term = fullTerm;
      const invert = term.startsWith("^");
      if (invert) term = term.slice(1);
      const [...allTerms] = viaDealias(term);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- viaDealias always returns at least one value
      const toValue = predicate(mapping, allTerms.at(-1)!, types);
      const { warnings } = toValue;
      const via: NodePredicate<G>[] = [];
      for (const viaTerm of allTerms.slice(0, -1)) {
        const viaValue = predicate(mapping, viaTerm, types);
        warnings.push(...viaValue.warnings);
        via.push(viaValue.predicate);
      }
      return {
        ...toValue,
        invert,
        term: fullTerm,
        via,
        warnings,
      };
    });
  };
};
