import { KubernetesComponents } from "shared/integrations/resources/kubernetes/components";

import { Install, InstallProps } from "../Install/Install";
import { k8sInstaller } from "./install/install";

export const API_PATH = "integrations/ssh/config";
export const K8sIconUrl =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Kubernetes_logo_without_workmark.svg/247px-Kubernetes_logo_without_workmark.svg.png";

export const K8s = (
  props: Pick<
    InstallProps<typeof KubernetesComponents>,
    "cardOverride" | "onInstall"
  >
) => (
  <Install<typeof KubernetesComponents>
    components={KubernetesComponents}
    installer={k8sInstaller}
    integration="k8s"
    itemKind="cluster"
    logo={K8sIconUrl}
    title={"Kubernetes"}
    {...props}
  />
);
