import { Alert, Button, Spin, Typography } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { AppRoutes } from "components/App/routeConstants";
import { Prefix } from "components/GraphTable/Prefix";
import { DateHistogram } from "components/Histogram/Histogram";
import { VerticalSpacedDiv } from "components/divs";
import { subDays } from "date-fns";
import { User } from "firebase/auth";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact, mapValues, sortBy } from "lodash";
import pluralize from "pluralize";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { useCallback, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";
import { AssessmentScope, toKey } from "shared/types/assessment";
import { AnyNode, AssessmentNodes } from "shared/types/assessment/data";
import {
  Finding,
  FindingNode,
  FindingState,
} from "shared/types/assessment/finding";
import { Monitor } from "shared/types/assessment/monitor";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import {
  ConnectedNode,
  DirectedGraph,
  Node,
} from "../../../shared/graph/types";
import { fromGrantKey } from "../../../shared/integrations/resources/gcloud/assessment";
import { Heading } from "../../Heading";
import { AssessmentGraph } from "../components/AssessmentGraph";
import { FindingsAssignModal } from "../components/FindingsAssignModal";
import { FindingsFixModal } from "../components/FindingsFixModal";
import { FindingsIgnoreModal } from "../components/FindingsIgnoreModal";
import { FindingsManageModal } from "../components/FindingsManageModal";
import { FindingsSelect } from "../components/FindingsSelect";
import { ScopeScoring } from "../components/ScopeSelect";
import { DetailsColumn } from "../components/cells/DetailsColumn";
import { SinceColumn } from "../components/cells/SinceColumn";
import { MonitorSeverity } from "../components/monitor/MonitorSeverity";
import {
  FindingsContext,
  MonitorWithFindings,
} from "../contexts/FindingsContext";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { useControls } from "../hooks/useControls";
import { useTracker } from "../hooks/useTracker";
import { fixScope } from "../scope";

export type MonitorActionProps = {
  actOn: FirestoreDoc<Finding>[];
  allNodes: AnyNode[] | undefined;
  count: number;
  findingFor: (node: AnyNode) => FirestoreDoc<Finding> | undefined;
  integration?: AssessmentScope["integration"];
  monitor: MonitorWithFindings;
  selectedNodes: AnyNode[] | undefined;
  state: FindingState;
  user?: User;
};

export const MonitorResults: React.FC = () => {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <MonitorResultsContent />
    </QueryParamProvider>
  );
};

const hasFixModal = (
  integration: AssessmentScope["integration"] | undefined,
  monitor: Monitor | undefined,
  state: FindingState
) =>
  !!integration &&
  !!monitor &&
  !!(
    (monitor.fix?.[fixScope(integration)] && state === "open") ||
    (monitor.revert?.[fixScope(integration)] && state === "resolved")
  );

const hasManageModal = (
  integration: AssessmentScope["integration"] | undefined,
  monitor: Monitor | undefined
) => {
  return !!integration && !!monitor && monitor.management;
};
const hasIgnoreToggle = (state: FindingState) =>
  state === "open" || state === "ignored";

const hasAssignModal = (
  state: FindingState,
  findings: FirestoreDoc<Finding>[]
) => state === "open" && findings.find((f) => !f.data.issue);

const syntheticNode = (
  node: FindingNode
): ConnectedNode<AssessmentNodes, keyof AssessmentNodes> | undefined => {
  let out;
  switch (node.type) {
    case "grant":
      {
        out = { ...node, data: fromGrantKey(node.key) };
      }
      break;
    case "principal":
      out = { ...node, data: {} };
      break;
    default:
      return undefined;
  }
  return { ...out, children: [], parents: [] };
};

const ActionBar: React.FC<Omit<MonitorActionProps, "actOn" | "count">> = (
  props
) => {
  const {
    assessmentFindingAssignment: hasAssignment,
    assessmentManage: hasManagement,
  } = useFlags();
  const { count, actOn } = useMemo(() => {
    const count = props.selectedNodes?.length ?? 0;
    const nodes = count ? props.selectedNodes : props.allNodes;
    const actOn = compact((nodes ?? []).map(props.findingFor));
    return { count, actOn };
  }, [props]);
  const tracker = useTracker();

  const innerProps = useMemo(
    () => ({ ...props, actOn, count }),
    [props, actOn, count]
  );

  return (
    <Prefix prefix="Actions">
      <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
        <ButtonGroup>
          {hasAssignment && !!tracker && hasAssignModal(props.state, actOn) && (
            <FindingsAssignModal {...innerProps} />
          )}
          {hasIgnoreToggle(props.state) && (
            <FindingsIgnoreModal {...innerProps} />
          )}
          {hasFixModal(props.integration, props.monitor, props.state) && (
            <FindingsFixModal {...innerProps} />
          )}
          {hasManagement &&
            hasManageModal(props.integration, props.monitor) && (
              <FindingsManageModal {...innerProps} />
            )}
        </ButtonGroup>
      </div>
      &nbsp;{count ? `${count} selected` : "all"}
      &nbsp;{pluralize("findings", count)}
    </Prefix>
  );
};

const MonitorResultsContent: React.FC = () => {
  const { monitorId, orgSlug, assessmentId } = useParams();
  const { current, runAssessmentNow } = useContext(SelectedAssessmentContext);
  const {
    allMonitors,
    loading,
    prioritized,
    range,
    setRange,
    state: findingsState,
  } = useContext(FindingsContext);
  const { graph, integration, scopeKey, step } = useContext(ScopeContext);
  const { controls, setControls } = useControls();
  const [searchedNodes, setSearchedNodes] = useState<AnyNode[]>();
  const [selectedNodes, setSelectedNodes] = useState<AnyNode[]>();

  const monitor = useMemo(
    () =>
      monitorId
        ? prioritized.find((m) => m.monitorId === monitorId) ??
          allMonitors[monitorId]
        : undefined,
    [monitorId, prioritized, allMonitors]
  );

  const monitorControls = useMemo(
    () => ({
      where: (monitor?.search ?? []).map((s) => s.term).join(" "),
      show: monitor?.show ?? "grant",
    }),
    [monitor]
  );

  const inferredControls = useMemo(() => {
    return { ...controls, show: monitorControls.show };
  }, [controls, monitorControls]);

  const findings = useMemo(() => monitor?.scopedFindings, [monitor]);

  const filteredGraph = useMemo<
    DirectedGraph<AssessmentNodes> | undefined
  >(() => {
    if (!monitorId || !graph) return undefined;
    if (!findings) return { nodes: [] };
    const nodes = sortBy(
      findings.map((d) => d.data.node),
      "key"
    );
    return {
      nodes: compact(
        nodes.map(
          (n) =>
            graph.nodes.find((nn) => n.key === nn.key && n.type === nn.type) ??
            syntheticNode(n)
        )
      ),
    };
  }, [monitorId, graph, findings]);

  // TODO: Improve performance if this ever matters
  const findingFor = useCallback(
    (node: Node<any, any>) =>
      findings?.find(
        (d) => d.data.node.key === node.key && d.data.node.type === node.type
      ),
    [findings]
  );

  const extraColumns = useMemo(() => {
    if (!assessmentId || !orgSlug || !monitorId || !findings) return undefined;
    return [
      DetailsColumn((node) => {
        const findingId = findingFor(node)?.id;
        return findingId
          ? {
              key: findingId,
              to: `/o/${orgSlug}/${
                AppRoutes.IamAssessment
              }/${assessmentId}/monitors/${monitorId}/findings/${findingId}?scope=${encodeURIComponent(
                scopeKey
              )}`,
            }
          : undefined;
      }),
      SinceColumn(findingsState, (node) => findingFor(node)?.data.trigger.at),
    ];
  }, [
    assessmentId,
    orgSlug,
    monitorId,
    findings,
    findingsState,
    findingFor,
    scopeKey,
  ]);

  const unevaluatedMonitorText = useMemo(() => {
    if (monitorId) {
      if (allMonitors[monitorId]?.scopedFindings?.length === 0) {
        return `This monitor has not been evaluated yet. Results for this monitor will be available after the next assessment job.`;
      } else {
        return `This monitor's search term has been updated, and the results on the page are out of date. Updated results for this monitor will be available after the next assessment job.`;
      }
    }
    return "";
  }, [monitorId, allMonitors]);

  const hasBulkSelect = useMemo(
    () =>
      hasIgnoreToggle(findingsState) ||
      hasFixModal(integration, monitor, findingsState),
    [findingsState, integration, monitor]
  );

  const frozen = useMemo(
    () => ({
      show: monitorControls.show,
      // Previously we displayed the monitor terms here, but these are
      // so long that they overrun the search bar.
      // TODO: Add the ability to optionally display the monitor search terms,
      // as well as wrap text in search bar.
      terms: "",
    }),
    [monitorControls.show]
  );

  // Rank scopes by number of applicable findings
  const scopeScoring: ScopeScoring = useMemo(() => {
    if (!monitor) {
      return () => 0;
    }
    const scopeCounts = mapValues(monitor.findingsByScope, (ff) => ff.length);
    return (scope) => scopeCounts[toKey(scope)] ?? 0;
  }, [monitor]);

  return monitorId && monitor ? (
    <>
      <Heading title={monitor.label} />
      <Typography.Paragraph>
        This monitor is marked as <MonitorSeverity monitor={monitor} />
      </Typography.Paragraph>
      {monitor.description && (
        <>
          <Typography.Title level={4}>Description</Typography.Title>
          <Typography.Paragraph>{monitor.description}</Typography.Paragraph>
        </>
      )}
      <Typography.Title level={4}>Findings</Typography.Title>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "fit-content",
        }}
      >
        {monitor.hasBeenEvaluated === false && (
          <Alert
            type="warning"
            description={unevaluatedMonitorText}
            message="One more thing..."
            action={
              current.isInProgress ? (
                <Typography.Text type="secondary">
                  Assessment job in progress
                </Typography.Text>
              ) : (
                <Button
                  type="default"
                  disabled={!current.isCompleted}
                  onClick={runAssessmentNow}
                >
                  Run an assessment now
                </Button>
              )
            }
            style={{
              marginBottom: 16,
              color: "#614700",
              alignItems: "center",
            }}
          />
        )}
        <VerticalSpacedDiv>
          {findings && (
            <DateHistogram
              data={loading ? undefined : findings} // Smoothly update on findings select
              value={(f) => new Date(f.data.trigger.at)}
              range={[
                range[0] ?? subDays(new Date(), 30),
                range[1] ?? new Date(),
              ]}
              setRange={setRange}
              label={findingsState === "open" ? "new" : findingsState} // Date on open finding is when it is new
            />
          )}
          <FindingsSelect includeRange scoring={scopeScoring} />
          {
            <AssessmentGraph
              graph={filteredGraph ?? { nodes: [] }}
              controls={inferredControls}
              onControls={setControls}
              onSearch={setSearchedNodes}
              onSelection={hasBulkSelect ? setSelectedNodes : undefined}
              searchExtra={
                hasBulkSelect && (
                  <ActionBar
                    allNodes={searchedNodes}
                    findingFor={findingFor}
                    integration={integration}
                    monitor={monitor}
                    selectedNodes={selectedNodes}
                    state={findingsState}
                  />
                )
              }
              frozen={frozen}
              extraColumns={extraColumns}
              step={step}
            />
          }
        </VerticalSpacedDiv>
      </div>
    </>
  ) : (
    <Spin />
  );
};
