import { MenuOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Segmented, Select, Spin, Typography } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { GraphSearch } from "components/GraphTable/GraphSearch";
import { Heading } from "components/Heading";
import { SpaceBetweenDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import stringify from "json-stable-stringify";
import { noop, uniqBy } from "lodash";
import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IamShowOptions } from "shared/assessment/constants";
import { assessmentParse } from "shared/assessment/issues/presets";
import { aggregate } from "shared/graph/aggregate";
import { discoverPaths } from "shared/graph/discover";
import { keyOf } from "shared/graph/graph";
import { NodePredicate } from "shared/graph/search";
import { ConnectedNode, Reducers } from "shared/graph/types";
import {
  AnyAggregates,
  AssessmentNodes,
  TargetNodeType,
  isTargetNodeType,
} from "shared/types/assessment/data";
import styled from "styled-components";
import { staticError } from "utils/console";
import { join } from "utils/join";

import { NodeDisplay } from "../components/node/NodeDisplay";
import { ScopeContext } from "../contexts/ScopeContext";
import { SelectedAssessmentContext } from "../contexts/SelectedAssessmentContext";
import { useControls } from "../hooks/useControls";
import { NodeLocaleVisualization } from "./AssessmentNodeLocale";
import { ReasonsDisplay } from "./ReasonsDisplay";
import { nodeDataFromShow } from "./node";
import { NodeLabel, NodeText } from "./node/NodeText";

const NODE_DISPLAY_OPTIONS = [
  { value: "table", label: <MenuOutlined /> },
  { value: "graph", label: <ShareAltOutlined /> },
];

const DetailSpan = styled.span`
  display: inline;
  white-space: nowrap;
`;

const DelimSpan = styled.span`
  margin-left: 0.3em;
  margin-right: 0.3em;
`;

export const ResultDetail: React.FC<{
  terms: string[];
  node: { key: string; type: string };
  show: TargetNodeType;
}> = ({ terms, node: { key, type }, show }): JSX.Element => {
  const { predicate, reducers } = useMemo(() => nodeDataFromShow[show], [show]);
  return (
    <InnerResultDetail
      from={predicate}
      terms={terms}
      node={{ key, type }}
      reducers={reducers as Reducers<AssessmentNodes, AnyAggregates>}
      show={show}
    />
  );
};

const InnerResultDetail = <A extends AnyAggregates>({
  from,
  terms,
  node: { key, type },
  reducers,
  show,
}: {
  from: NodePredicate<AssessmentNodes>;
  terms: string[];
  node: { key: string; type: string };
  reducers: Reducers<AssessmentNodes, A>;
  show: TargetNodeType;
}): ReactElement => {
  const {
    last: { loading },
  } = useContext(SelectedAssessmentContext);
  const [selectedTermIndex, setSelectedTermIndex] = useState(0);
  const { graph, integration } = useContext(ScopeContext);
  const [reason, setReason] = useState<ReactNode[][]>();
  const { controls, setControls } = useControls();

  const setDisplayMode = useCallback(
    (value: string) => {
      setControls({ ...controls, display: value });
    },
    [controls, setControls]
  );

  const node = useMemo(() => {
    const node = graph?.nodes.find((n) => n.type === type && n.key === key);
    if (!node) return undefined;
    return aggregate({ nodes: [node] }, reducers).nodes[0];
  }, [graph, key, type, reducers]);

  const nodeRenderer = useMemo(
    () =>
      integration
        ? NodeText(node, integration, {
            detailed: true,
            hideThis: true,
          })
        : undefined,
    [integration, node]
  );

  const detailTitle =
    node && integration ? NodeLabel(node, integration) : "Not found";

  const termOptions = useMemo(() => {
    return terms.map((t, ix) => ({ value: ix, label: t })) ?? [];
  }, [terms]);

  useGuardedEffect(
    async (cancellation) => {
      if (!node || !integration || selectedTermIndex === undefined)
        return undefined;
      // Only ever one node
      const graph = {
        nodes: [node as ConnectedNode<AssessmentNodes, keyof AssessmentNodes>],
      };
      const [discovered] = await discoverPaths(
        graph,
        from,
        assessmentParse(terms.join(" "))
      );
      const results = discovered?.matches?.[selectedTermIndex];
      if (!results) return cancellation.guard(setReason)([]);
      const { paths } = results;
      const uniqPaths = uniqBy(paths, (p) => stringify(p.map(keyOf)));
      const renderer = NodeText(node, integration);
      const textPaths = uniqPaths
        .map((p) =>
          join(
            p.map((n, ix) => (
              <DetailSpan key={ix}>
                {renderer[n.type]?.(n as any) ?? n.key}
              </DetailSpan>
            )),
            (ix) => <DelimSpan key={`delim-${ix}`}>→</DelimSpan>
          )
        )
        .sort();
      cancellation.guard(setReason)(textPaths);
    },
    staticError,
    [node, selectedTermIndex, from, terms, integration]
  );

  if (loading) return <Spin />;
  if (!graph || !node || !isTargetNodeType(node))
    return (
      <div>
        <Heading title="Not Found" />
      </div>
    );
  return (
    <div>
      <SpaceBetweenDiv>
        <Typography.Title level={4}>This {detailTitle}</Typography.Title>
        <Segmented
          options={NODE_DISPLAY_OPTIONS}
          value={controls.display ?? "table"}
          onChange={setDisplayMode as Dispatch<SetStateAction<SegmentedValue>>}
        />
      </SpaceBetweenDiv>
      {controls.display === "graph" ? (
        integration &&
        nodeRenderer && (
          <NodeLocaleVisualization
            current={node}
            integration={integration}
            renderer={nodeRenderer}
          />
        )
      ) : (
        // Default to table view
        <div style={{ maxWidth: "800px" }}>
          <NodeDisplay node={node} />
        </div>
      )}
      {!!reason?.length && (
        <>
          <Typography.Title level={4} style={{ marginTop: "1em" }}>
            Explanation
          </Typography.Title>
          <Typography.Paragraph>
            This {detailTitle} was the result of searching for:
          </Typography.Paragraph>
          <div style={{ margin: "1em", marginBottom: "2em" }}>
            <GraphSearch
              showOptions={IamShowOptions}
              frozen={{ terms: terms.join(" "), show }}
              controls={{
                display: "table",
                terms: "",
                show,
                onChangeDisplay: noop,
                onChangeTerms: noop,
                onChangeShow: noop,
              }}
              isSearching={false}
            />
          </div>
          {terms.length > 1 && (
            <>
              <Typography.Paragraph>
                To see why this grant matches this search, select an individual
                term below:
              </Typography.Paragraph>
              <Select
                options={termOptions}
                value={selectedTermIndex}
                onChange={setSelectedTermIndex}
                placeholder="Select a search term"
              >
                {selectedTermIndex && terms[selectedTermIndex]}
              </Select>
            </>
          )}
          <ReasonsDisplay reasons={reason} />
        </>
      )}
    </div>
  );
};
