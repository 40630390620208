import { distinct, max, paint } from "../../graph/aggregate";
import { Reducers, isNode } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import { PrincipalAggregates } from "../../types/assessment/data/principal";
import { STALE_CREDENTIAL_MILLIS } from "../issues/presets";
import { grantAggregate, keyAggregate, permissionAggregate } from "./shared";

export const principalReducers: Reducers<AssessmentNodes, PrincipalAggregates> =
  {
    // TODO: Fix node aggregation to return nodes instead of keys so this is no longer necessary
    // Data duplication on auth and credential nodes is done to be able to use the `paint` aggregator
    usedCredentials: paint(
      (n) => (isNode("authentication")(n) ? n.data.type : undefined),
      distinct(
        "credential",
        (node) => node,
        (node) => node.key
      )
    ),
    staleCredentials: paint(
      (n) =>
        isNode("authentication")(n)
          ? n.data.credentialType === "key" &&
            n.data.credentialCreatedTime &&
            Date.now() - n.data.credentialCreatedTime > STALE_CREDENTIAL_MILLIS
            ? "stale"
            : "fresh"
          : undefined,
      distinct(
        "credential",
        (node) => node,
        (node) => node.key
      )
    ),
    lastAuthEvent: max("authentication", (n) => n.lastAuthnTime),
    grants: grantAggregate,
    permissions: permissionAggregate,
    risks: keyAggregate("risk"),
  };
