import { base64decode } from "../../../base64";

const CLUSTER_ID_PATTERN = /^[a-zA-Z0-9_-]+$/;
const CLUSTER_SERVER_PATTERN = /^https:\/\/\S+$/;
const PEM_PATTERN =
  /^-----BEGIN CERTIFICATE-----\n[\s\S]+\n-----END CERTIFICATE-----$/;

export const clusterIdCheck = (value: string) => {
  return typeof value !== "string" || !value.match(CLUSTER_ID_PATTERN);
};

export const clusterIdValidator = async (value: any) => {
  if (clusterIdCheck(value)) {
    throw new Error("Invalid cluster ID");
  }
};

export const clusterServerCheck = (value: string) => {
  return typeof value !== "string" || !value.match(CLUSTER_SERVER_PATTERN);
};

export const clusterServerValidator = async (value: any) => {
  if (clusterServerCheck(value)) {
    throw new Error("Invalid cluster server");
  }
};

export const clusterCertificateBase64Check = (value: string) => {
  return clusterCertificatePemCheck(base64decode(value).trimEnd());
};

export const clusterCertificatePemCheck = (value: string) => {
  return typeof value !== "string" || !value.match(PEM_PATTERN);
};

const clusterCertificateBase64Validator = (value: any) => {
  if (typeof value !== "string") {
    throw new Error("Certificate authority not string");
  }
  const decoded = base64decode(value).trimEnd(); // decode appends a newline to the end
  clusterCertificatePemValidator(decoded);
};

export const clusterCertificatePemValidator = (value: any) => {
  if (clusterCertificatePemCheck(value)) {
    throw new Error("Certificate authority not in valid pem format");
  }
};

export const clusterCertificateValidator = async (value: any) => {
  try {
    clusterCertificateBase64Validator(value);
  } catch (err: any) {
    clusterCertificatePemValidator(value);
  }
};

export const clusterCertificateCheck = (value: string) => {
  return (
    clusterCertificateBase64Check(value) || clusterCertificatePemCheck(value)
  );
};
