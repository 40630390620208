import { size } from "lodash";

import { IntegrationStatus } from "../integrations/shared";
import { widetype } from "../util/collections";
import { BaseComponent } from "./types";

type GenericConfig<K extends string> = Record<K, Record<string, BaseComponent>>;

export const items = <K extends string, D extends GenericConfig<K>>(
  component: K,
  config: D,
  state: IntegrationStatus
): Partial<D[K]> => {
  const comp = config[component];
  if (!comp) return {};
  return widetype.pickBy(comp, (value) => value.state === state);
};

export const installedItems = <K extends string, D extends GenericConfig<K>>(
  component: K,
  config: D
) => items(component, config, "installed");

export const installedIds = <K extends string, D extends GenericConfig<K>>(
  component: K,
  config: D
) => Object.keys(installedItems(component, config));

export const anyIsInstalled = <D extends GenericConfig<string>>(config: D) => {
  return !!Object.values(config).find(
    (c) =>
      typeof c === "object" &&
      !!Object.values(c).find((i) => i?.state === "installed")
  );
};

export const installedItem = <K extends string, D extends GenericConfig<K>>(
  component: K,
  config: D,
  id: string
): D[K][string] | undefined => {
  const candidate = config[component]?.[id];
  if (candidate?.state !== "installed") return undefined;
  return candidate as D[K][string];
};

/** Creates an `isInstalled` callback for `Pool.get` */
export const isInstalled =
  <P extends { use: string; component: string }, R extends Array<P>>(
    mapping: R
  ) =>
  <D extends GenericConfig<P["component"]>, U extends string>(
    config: D,
    use: U
  ) => {
    if (use === "any") return anyIsInstalled(config);
    return mapping
      .filter((m) => m.use === use)
      .some((m) => size(items(m.component, config, "installed")) > 0);
  };
