import {
  Element,
  InstallSpec,
  ItemComponent,
  Option,
} from "../../../install/types";
import { AWS_ACCOUNT_PATTERN } from "./constants";

const OKTA_APP_PATTERN = /^0o\w+$/;
const AWS_IDP_PATTERN = /^[\w.\-/]+$/;

const iamLoginOption = Option({
  label: "As AWS IAM users",
  schema: {
    identity: Element({
      label: "How are users identified?",
      type: "select",
      default: "email",
      options: {
        email: Option({
          label: "User name is user email",
          schema: {},
        }),
        tag: Option({
          key: "tag" as const,
          label: "User email in a tag",
          schema: { tagName: Element({ type: "string", label: "Tag name" }) },
        }),
      },
    }),
  },
});

const idcLoginOption = Option({
  label: "Via AWS Identity Center",
  schema: {
    parent: Element({
      label: "Parent AWS Account",
      type: "dynamic",
    }),
  },
});

const federatedLoginOption = Option({
  label: "Via a federated identity provider",
  schema: {
    provider: Element({
      label: "Identity provider",
      description: "Only Okta is supported at this time",
      type: "select",
      default: "okta",
      options: {
        okta: Option({
          key: "okta",
          label: "Okta",
          schema: {
            appId: Element({
              label: "Federation app ID",
              description:
                "Application ID of your AWS federation application, in Okta.",
              type: "string",
              validator: async (_id, field) => {
                if (!field.match(OKTA_APP_PATTERN))
                  return "Okta app IDs should start with 0o and include no special characters";
              },
            }),
            identityProvider: Element({
              label: "Provider integration",
              description:
                "Identity of your identity provider integration, in AWS.",
              type: "string",
              validator: async (_id, field) => {
                if (!field.match(AWS_IDP_PATTERN))
                  return "AWS Identity Providers should contain no special characters other than /.";
              },
            }),
            method: Element({
              label: "SSO method",
              type: "select",
              default: "saml",
              options: {
                saml: {
                  label: "SAML",
                  schema: {
                    accountCount: Element({
                      label: "Number of accounts linked to Federation app",
                      type: "select",
                      default: "single",
                      options: {
                        single: { label: "Single account", schema: {} },
                        multi: {
                          label: "Multiple accounts (legacy)",
                          description:
                            'Choose if you have multiple "Connected Accounts IDs" on the Provisioning / Integration tab in your Federation app',
                          schema: {
                            parent: Element({
                              label: "Parent AWS Account",
                              type: "dynamic",
                            }),
                          },
                        },
                      },
                    }),
                  },
                },
              },
            }),
          },
        }),
      },
    }),
  },
});

export const loginOptions = {
  iam: iamLoginOption,
  idc: idcLoginOption,
  federated: federatedLoginOption,
};

const awsIamWriteValidator = async (_context: any, id: string) => {
  if (!id.match(AWS_ACCOUNT_PATTERN))
    return "AWS accounts should be 12 numeric digits";
};

export const AwsComponents = InstallSpec({
  /** Base is a generated component. It carries P0's service-account integration data.
   *
   * This is not created directly by the user; rather, the user-initiated component
   * assemblers should assemble this component implicitly.
   */
  base: ItemComponent({
    label: "Base installation",
    description: "Defines how P0 connects to your AWS account",
    type: "generated",
    hidden: true,
    schema: {
      serviceAccountEmail: Element({
        label: "P0 service account human-readable identifier",
        type: "generated",
      }),
      serviceAccountId: Element({
        label: "P0 service account machine identifier",
        type: "generated",
      }),
    },
  }),
  "iam-assessment": ItemComponent({
    label: "IAM assessment",
    description:
      "Allows P0 to analyze your IAM configuration for security issues.",
    type: "string",
    schema: {
      accessAnalyzerArn: Element({
        label: "ARN of this account's IAM Access Analyzer",
        type: "string",
      }),
    },
  }),
  "iam-write": ItemComponent({
    label: "IAM management",
    description: "Allows P0 to grant and revoke AWS access for your users.",
    type: "string",
    schema: {
      login: Element({
        label: "How are users provisioned?",
        type: "select" as const,
        default: "iam",
        options: loginOptions,
      }),
    },
    validator: awsIamWriteValidator,
  }),
  inventory: ItemComponent({
    label: "Resource inventory",
    description:
      "Allows P0 to inventory your AWS resources. Required for resource-level access management.",
    type: "dynamic",
    schema: {},
  }),
});
