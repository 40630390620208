import { Node } from "shared/graph/types";
import {
  AssessmentNodes,
  GrantNode,
  PrincipalNode,
  ResourceNode,
  TargetNodeType,
} from "shared/types/assessment/data";
import { CredentialNode } from "shared/types/assessment/data/credential";
import { assertNever } from "utils/assert";

import { CredentialNodeDisplay } from "./credential/CredentialNode";
import { GrantNodeDisplay } from "./grant/GrantNode";
import { PrincipalNodeDisplay } from "./principal/PrincipalNode";
import { ResourceNodeDisplay } from "./resource/ResourceNode";

export const NodeDisplay: React.FC<{
  node: Node<AssessmentNodes, TargetNodeType>;
}> = ({ node }) => {
  switch (node.type) {
    case "grant":
      return <GrantNodeDisplay node={node as GrantNode} />;
    case "credential":
      return <CredentialNodeDisplay node={node as CredentialNode} />;
    case "principal":
      return <PrincipalNodeDisplay node={node as PrincipalNode} />;
    case "resource":
      return <ResourceNodeDisplay node={node as ResourceNode} />;
    default:
      throw assertNever(node.type);
  }
};
