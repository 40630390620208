import pluralize from "pluralize";

import AwsServices from "../assessment/media/aws-services.json";
import { isNode } from "../graph/types";
import { CRM_API_PREFIX } from "../integrations/resources/gcloud/constants";
import { GrantNode } from "../types/assessment/data";
import { AWS_RESOURCE_ARN } from "./constants";
import { fallbackIconUrl, serviceIconUrl } from "./media/Aws";
import { names, navIconUrl, serviceOf } from "./media/GoogleCloud";

type ResourceInfo = {
  /** The resource service name */
  service: string;
  /** Any potential subservice */
  first?: string;
  /** The terminal resource name */
  name: string;
  /** Is not a specific managed service */
  isGeneric: boolean;
  /** Is an entire service */
  isService: boolean;
  /** The service's icon URL */
  url: string;
};

/* Helper function to fetch all permissions in a grant with a given risk */
export const permissionsWithRisk = (node: GrantNode, risk: string) => {
  return node.aggregates.permissions["unused"]
    .filter(
      (p) => !!p.children.find((n) => isNode("risk")(n) && n.data.name === risk)
    )
    .map((p) => p.key);
};

const gcloudResourceTransform = (resource: string) => {
  if (!resource) return "(unknown)";
  const [, , api, levelType, levelName, ...rest] = resource.trim().split("/");
  if (levelType && levelName && rest.length === 0) {
    // check org, folder, and project cases:
    // org format is //cloudresourcemanager.googleapis.com/organizations/orgId
    // folder is //cloudresourcemanager.googleapis.com/folders/folderId
    // project is //cloudresourcemanager.googleapis.com/project/folderId
    if (levelType === "projects") return `projects/${levelName}`;
    if (levelType === "organizations") return "(organization)";
    if (levelType === "folders") return `folders/${levelName}`;
  }
  const items = rest?.join("/") ?? [];
  if (!levelType) return `service/${api}`;
  if (!levelName) return levelType;
  return items;
};

export const gcloudResourceInfo = (
  resource: string,
  shortPath?: boolean
): ResourceInfo => {
  const isGeneric = resource.startsWith(CRM_API_PREFIX);
  let service = serviceOf(resource);
  service = names[service] ?? service;
  let [first, ...rest] = gcloudResourceTransform(resource).split("/");
  const isService = first === "service";
  // Resource is a service node
  if (isService) first = `${names[rest[0]] ?? rest[0]}`;
  // Re-parse compute-engine global resources
  if (first === "global") {
    [first, ...rest] = rest;
  }
  // When used in nested display, only show last path element
  if (rest.length > 2 && shortPath) {
    [first, ...rest] = rest.slice(-2);
  }
  const name = isService ? service : rest.length > 0 ? rest.join("/") : first;
  const url = navIconUrl(resource);
  return {
    service,
    first: isService ? undefined : rest.length > 0 ? first : undefined,
    name,
    isGeneric,
    isService,
    url,
  };
};

export const awsResourceInfo = (
  resource: string,
  _shortPath?: boolean
): ResourceInfo => {
  const match = resource.match(AWS_RESOURCE_ARN);
  if (match) {
    const [, service, , first, name] = match;
    // AwsServices is downloaded from https://github.com/fluggo/aws-service-auth-reference/blob/master/service-auth.json
    // then passed through jq:
    //   jq 'map( { (.servicePrefix): del(.|.actions) }) | add
    // TODO: Automate periodic collection
    const humanService = (AwsServices as any)[service]?.name ?? service;
    return {
      service: humanService,
      first: first ? first.replace(/-/g, " ") : undefined,
      name:
        name === "*"
          ? first
            ? `All ${pluralize(first?.replace(/-/g, " "))}`
            : service === "*"
            ? resource
            : `All ${service} resources`
          : name,
      isGeneric: true,
      isService: !first,
      url: serviceIconUrl[service] ?? fallbackIconUrl,
    };
  }
  return {
    service: resource === "*" ? "AWS accounts" : "unknown",
    first: undefined,
    name: resource === "*" ? "Entire account" : resource,
    isGeneric: true,
    isService: false,
    url: fallbackIconUrl,
  };
};
