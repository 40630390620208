import { Node } from "../graph/types";
import { awsUrlFor } from "../integrations/resources/aws/constants";
import { assertNever } from "../types";
import {
  AssessmentScopeIntegration,
  ItemAssessmentScope,
  toScope,
} from "../types/assessment";
import {
  AssessmentNodes,
  GrantNode,
  PrincipalNode,
  PrincipalType,
} from "../types/assessment/data";
import { Monitor } from "../types/assessment/monitor";
import { Risk } from "../types/catalog";
import { ALL_SCOPE_SENTINEL, memberTypeToLabel } from "./constants";
import { awsResourceInfo, gcloudResourceInfo } from "./helper";

export type PrincipalNodeLabels = {
  principal: string;
  principalType: string;
  type: "principal";
};

export type GrantNodeLabels = {
  principal: string;
  principalType: string;
  permissionSet: string;
  resource: string;
  type: "grant";
};

export type NodeLabels = GrantNodeLabels | PrincipalNodeLabels;

export const grantNodeLabels = (
  integration: ItemAssessmentScope["integration"],
  node: GrantNode
): GrantNodeLabels => ({
  principal: node.data.principal,
  principalType: memberTypeToLabel(integration)[node.data.principalType],
  permissionSet: grantNodeToPermissionSet(node),
  resource:
    integration === "gcloud"
      ? gcloudResourceInfo(node.data.resource, false).name
      : integration === "aws"
      ? awsResourceInfo(node.data.resource, false).name
      : assertNever(integration),
  type: "grant",
});

export const principalNodeLabels = (
  integration: ItemAssessmentScope["integration"],
  { data }: PrincipalNode
): NodeLabels => ({
  principal: data.label,
  principalType: memberTypeToLabel(integration)[data.principalType],
  type: "principal",
});

export const grantNodeToPermissionSet = (
  node: Node<AssessmentNodes, "grant">
) =>
  // Handles both gcp `projects/1234/roles/foo` and AWS `Policy:Statement`
  node.data.permissionSet.split("/").at(-1)?.split(":")?.[0] ??
  "Unknown permission set";

export const findingTitle = (monitor: Monitor, labels: NodeLabels) =>
  `${monitor.label}: ${labels.principal}`;

export const riskLink = (risk: Pick<Risk, "id" | "name" | "score">) =>
  `https://catalog.p0.dev/risks/${risk.id}`;

export const privilegeLink = (
  integration: AssessmentScopeIntegration,
  privilege: string
) =>
  `https://catalog.p0.dev/services/${
    integration === "gcloud" ? "gcp" : "aws"
  }/${privilege}`;

export const principalLink = (
  principal: string,
  principalType: PrincipalType,
  scopeKey: string,
  integration?: AssessmentScopeIntegration,
  idcId?: string | undefined
): string | undefined =>
  integration === "aws" && scopeKey !== ALL_SCOPE_SENTINEL && idcId
    ? awsUrlFor(principalType)?.({
        idcId: idcId,
        accountId: toScope(scopeKey).id,
        roleName: principal,
      })
    : undefined;
