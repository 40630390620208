import {
  Element,
  InstallSpec,
  ItemComponent,
  Option,
} from "../../../install/types";
import { arnInfo, isArn } from "../aws/util";
import { clusterCertificateCheck, clusterServerCheck } from "./validator";

export const KubernetesComponents = InstallSpec({
  base: ItemComponent({
    label: "Kubernetes",
    description: "Kubernetes",
    type: "generated",
    hidden: true,
    schema: {
      serverKey: Element({
        label: "Server key",
        type: "string",
      }),
      serverCert: Element({
        label: "Server certificate",
        type: "string",
      }),
      caBundle: Element({
        label: "CA bundle",
        type: "string",
      }),
    },
  }),
  "iam-write": ItemComponent({
    label: "IAM management",
    description:
      "Allows P0 to grant and revoke Kubernetes access for your users.",
    type: "string",
    schema: {
      endpoint: Element({
        label: "Cluster endpoint",
        description: "Cluster HTTPS URL",
        step: "new",
        type: "string",
        validator: async (_id, field) => {
          if (clusterServerCheck(field)) {
            return "Invalid Cluster Endpoint.";
          }
        },
      }),
      ca: Element({
        label: "Cluster certificate authority",
        description:
          "The certificate authority used to validate the cluster identity",
        type: "string",
        step: "new",
        multiline: true,
        validator: async (_id, field) => {
          if (!field) {
            return "Provide the cluster certificate.";
          }
          try {
            if (!clusterCertificateCheck(field))
              return "Provide Certificate in raw pem or base64-encoded pem format.";
          } catch (err: any) {
            return "Provide Certificate in raw pem or base64-encoded pem format.";
          }
        },
      }),
      token: Element({
        label: "Cluster token",
        type: "encrypted",
        description: "The token used to authenticate with the cluster",
        validator: async (_id, field) => {
          if (!field) {
            return "Please provide the token of the p0-service-account-secret";
          }
        },
      }),
      connectivity: Element({
        label: "Network connectivity",
        description: "How P0 communicates with this cluster",
        type: "select",
        default: "public",
        step: "new",
        options: {
          public: Option({
            label: "Public Internet",
            description:
              "This cluster's API is available over the public Internet",
            schema: {},
          }),
          proxy: Option({
            label: "P0 proxy",
            description: "Use P0's Braekhus reverse HTTPS proxy",
            schema: {
              publicJwk: Element({
                label: "Public JWK",
                description:
                  "JWK used by the cluster to authenticate to the Braekhus proxy.", //the token and cluster authority are both needed to initialize the kubernetes client to connect to the cluster without the proxy.
                type: "string",
                multiline: true,
                validator: async (_id, field) => {
                  if (!field) {
                    return "Please provide the JWK from the proxy";
                  }
                },
              }),
            },
          }),
        },
      }),
      hosting: Element({
        label: "Hosting",
        description: "Where this cluster is hosted",
        type: "select",
        default: "aws",
        step: "new",
        options: {
          aws: Option({
            label: "Amazon Web Services",
            description:
              'Requires P0 installed for "IAM management" in the cluster\'s AWS account',
            schema: {
              arn: Element({
                label: "Cluster ARN",
                type: "string",
                step: "new",
                validator: async (field) => {
                  if (!isArn(field)) return "Not a valid AWS ARN";
                  const { service, accountId, region, resourceType } =
                    arnInfo(field);
                  if (service !== "eks") return "Not an EKS ARN";
                  if (!accountId) return "ARN is missing account ID";
                  if (!region) return "ARN is missing region";
                  if (resourceType !== "cluster") return "Not an EKS cluster";
                },
              }),
            },
          }),
          azure: Option({
            label: "Azure",
            description: "Hosted in AKS",
            schema: {},
          }),
          gcp: Option({
            label: "Google Cloud",
            description: "Hosted in GKE",
            schema: {},
          }),
        },
      }),
    },
  }),
});
