import { SnowflakeComponents } from "shared/integrations/resources/snowflake/components";

import { useOktaGroups } from "../../../hooks/useOktaGroups";
import { useAuthFetch } from "../../Login/hook";
import { Install, InstallProps } from "../Install/Install";
import { snowflakeInstaller } from "./install/install";

export const SnowflakeIconUrl =
  "https://avatars.githubusercontent.com/u/6453780?s=200&v=4";

export const Snowflake = (
  props: Pick<
    InstallProps<typeof SnowflakeComponents>,
    "cardOverride" | "onInstall"
  >
) => {
  const authFetch = useAuthFetch();
  // Only support Okta SCIM for now
  // using oktaAuthFetch to separate the error state from the main authFetch
  // failure in installation and configuration are different
  const { groups } = useOktaGroups(authFetch);
  return (
    <Install<typeof SnowflakeComponents>
      components={SnowflakeComponents}
      installer={snowflakeInstaller(groups)}
      integration="snowflake"
      itemKind="Account"
      logo={SnowflakeIconUrl}
      title={"Snowflake"}
      {...props}
    />
  );
};
