import { grantPredicate } from "shared/assessment/issues/presets";
import { grantReducers } from "shared/assessment/reducers/grant";

import { grantColumns } from "./columns";

export const grantData = {
  predicate: grantPredicate,
  reducers: grantReducers,
  columns: grantColumns,
};
