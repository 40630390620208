import { useFirestoreDoc } from "providers/FirestoreProvider";
import { AwsIntegration } from "shared/integrations/resources/aws/types";
import { GcloudIntegration } from "shared/integrations/resources/gcloud/types";
import { SshComponents } from "shared/integrations/resources/ssh/components";

import { Install, InstallProps } from "../Install/Install";
import { sshInstaller } from "./install/install";

export const API_PATH = "integrations/ssh/config";

// This image is in the public domain See https://commons.wikimedia.org/wiki/File:Terminalicon2.png
export const SshIconUrl =
  "https://upload.wikimedia.org/wikipedia/commons/b/b3/Terminalicon2.png";

export const Ssh = (
  props: Pick<InstallProps<typeof SshComponents>, "cardOverride" | "onInstall">
) => {
  const aws = useFirestoreDoc<AwsIntegration>("integrations/aws", {
    tenantAware: true,
  });
  const gcloud = useFirestoreDoc<GcloudIntegration>("integrations/gcloud", {
    tenantAware: true,
  });

  const cspConfigs = { aws, gcloud };

  return (
    <Install<typeof SshComponents>
      components={SshComponents}
      installer={sshInstaller(cspConfigs)}
      integration="ssh"
      itemKind="account"
      logo={SshIconUrl}
      title={"SSH"}
      {...props}
    />
  );
};
